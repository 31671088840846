import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, Input, DatePicker, Select } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import _ from 'underscore';

import { fetchVendorIndex } from "../../redux/vendorSlice";

import "./EditSupplier.scss";
import { ImageBoxForVendorBanner } from "../../components";
import { getBase64 } from "../../helpers";

const EditsingleSupplier = ({ vendor, index, form, setVendor }) => {
    const { t } = useTranslation('translation');

    const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);

    const [newProductTypeSizeChart, setNewProductTypeSizeChart] = useState();
    const [updatedVendor, setUpdatedVendor] = useState({ name: vendor?.name, _id: vendor?._id, translation_config: vendor?.translation_config });
    const [files, setFiles] = useState([]);
    const [bannerFileList, setBannerFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    
    useEffect(() => {
        setVendor(vendor?.name, { ...updatedVendor, files, setFiles, bannerFileList })
    }, [vendor?.name, updatedVendor, files, bannerFileList]);

    useEffect(() => {
        if(vendor?._id) {
            fetchVendorIndex({ _id: vendor?._id })
                .then(res => {
                    const responseVendor = res?.data[0];

                    let initialValues = {};
                    initialValues[`${index}-instagram`] = responseVendor.instagram;
                    initialValues[`${index}-location`] = responseVendor.location;
                    initialValues[`${index}-founding_year`] = moment(responseVendor.founding_year);
                    initialValues[`${index}-about_en`] = responseVendor.abouts?.en;
                    initialValues[`${index}-about_tr`] = responseVendor.abouts?.tr;
                    
                    setBannerFileList(responseVendor?.banner_images?.map((image, index) => {
                        return {
                          uid: index + 1,
                          url: image,
                          name: `Image_${index + 1}`,
                          existBeforeUpdate: true,
                          status: "done",
                          is_main: responseVendor?.banner === image
                        }
                    }));

                    form.setFieldsValue(initialValues);
                });
        }
    }, [vendor?._id, form, index]);

    const uploadButton = (name) => (
        <label className="custom-file-upload">
            <input type="file" onChange={(e) => {
                setFiles(files => [...files?.filter(f => f?.type !== name), { file: e.target.files[0], type: name }]);
                
                let updatedVendorCopy = { ...updatedVendor };
                updatedVendorCopy[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setUpdatedVendor(updatedVendorCopy)
            }}/>
            <UploadOutlined />
            {'  '}{t(`buttons.Upload ${name}`)}
        </label>
    )

    const uploadProductTypeSizeChartButton = (name) => (
        <label className="custom-file-upload">
            <input type="file" onChange={(e) => {
                setFiles(files => [...files?.filter(f => f?.type !== name), { file: e.target.files[0], type: name, productTypeSizeChart: true }]);

                let updatedVendorCopy = JSON.parse(JSON.stringify(updatedVendor));
                if (!vendor?.product_type_size_charts) updatedVendorCopy.product_type_size_charts = { ...vendor?.product_type_size_charts } || {};
                updatedVendorCopy.product_type_size_charts[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setUpdatedVendor(updatedVendorCopy)
            }}/>
            <UploadOutlined />
            {'  '}{(`Upload ${name} Size Chart`)}
        </label>
    )

    return(
        <div className="form-top-supplier">
            <h2 className="product-header">{`${vendor?.name}`}</h2>
            <div className="inline-inputs-supplier">
                <Form.Item label={t(`fields.supplier_info.Instagram`)} name={`${index}-instagram`}>
                    <Input
                        value={updatedVendor?.instagram}
                        onChange={(e) => {
                            setUpdatedVendor({ ...updatedVendor, instagram: e.target.value });
                        }}>
                    </Input>
                </Form.Item>
                <Form.Item label={t(`fields.supplier_info.Location`)}  name={`${index}-location`}>
                    <Input
                        value={updatedVendor?.location}
                        onChange={(e) => {
                            setUpdatedVendor({ ...updatedVendor, location: e.target.value });
                        }}>
                    </Input>
                </Form.Item>
                <Form.Item label={t(`fields.supplier_info.Founding Year`)}  name={`${index}-founding_year`}>
                    <DatePicker
                        className="founding-year"
                        picker="year"
                        value={updatedVendor?.founding_year ? moment(updatedVendor?.founding_year) : null}
                        onChange={(e) => {
                            setUpdatedVendor({ ...updatedVendor, founding_year: (new Date(e._d)).getFullYear().toString() })
                        }}
                    />
                </Form.Item>
            </div>

            <div>
                <Form.Item label={t(`fields.supplier_info.About Supplier`)}  name={`${index}-about_en`}>
                    <TextArea
                        value={updatedVendor?.abouts?.en}
                        rows={6}
                        onChange={(e) => {
                            setUpdatedVendor({
                                ...updatedVendor,
                                "abouts.en": e.target.value,
                            })
                        }}
                    />
                </Form.Item>
            </div>

            <div>
                <Form.Item label={t(`fields.supplier_info.About Supplier (Turkish)`)} name={`${index}-about_tr`}>
                    <TextArea
                        value={updatedVendor?.abouts?.tr}
                        rows={6}
                        onChange={(e) => {
                            setUpdatedVendor({
                                ...updatedVendor,
                                "abouts.tr": e.target.value,
                            })
                        }}
                    />
                </Form.Item>
            </div>

            <div className="image-container">
                <div className="image-div">
                    {uploadButton("Logo")}
                    <img alt="logo" src={updatedVendor?.logo || vendor?.logo} className="image"/>
                </div>

               {/*  <div className="image-div">
                    {uploadButton("Banner")}
                    <img alt="banner" src={updatedVendor?.banner || vendor?.banner} className="image"/>
                </div> */}

                <div className="image-div">
                    {uploadButton("Size_Chart")}
                    <img alt="size chart" src={updatedVendor?.size_chart || vendor?.size_chart} className="image"/>
                </div>
            </div>

            <div className="vendor-image-box-container">
                <ImageBoxForVendorBanner 
                    fileList={bannerFileList}
                    setFileList={setBannerFileList}
                    handlePreview={handlePreview}
                    previewVisible={previewVisible}
                    previewTitle={previewTitle}
                    previewImage={previewImage}
                    setPreviewVisible={setPreviewVisible}
                />
            </div>

            <div className="product-type-size-chart-images">
                <h4 className="product-type-size-chart-images-header">Size Charts by Product Type</h4>

                <div className="product-type-size-chart-add-new">
                    <span>Add New Size Chart:</span>
                    <Form.Item name="product_type">
                        <Select
                            onChange={(e) => {
                                setNewProductTypeSizeChart(e);
                            }}
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                        >
                            <Option value="all">All Types</Option>
                            
                            {productTypes?.slice().sort((a, b) => a.names?.en?.localeCompare(b.names?.en)).map((type, index) => (
                                <Option key={'vendor-select' + index} value={type?.handle}>
                                    {type?.names?.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {newProductTypeSizeChart && uploadProductTypeSizeChartButton(newProductTypeSizeChart)}

                </div>

                <div className="product-type-size-chart-images-container">
                    { Object.keys(vendor?.product_type_size_charts || {})?.map(product_type => (
                        <div className="product-type-size-chart-image-container">

                            <div 
                                className="product-type-size-chart-image-trash"
                                onClick={() => {
                                    setFiles(files => files?.filter(f => f?.type !== product_type));

                                    let newUpdatedVendorSizeCharts = (updatedVendor?.product_type_size_charts || {});
                                    newUpdatedVendorSizeCharts = _.omit(newUpdatedVendorSizeCharts, product_type?.toLowerCase());
                                    setUpdatedVendor(v => ({ ...v, product_type_size_charts: newUpdatedVendorSizeCharts }));
                                }}
                            >
                                <DeleteOutlined />
                            </div>

                            <span>{product_type}</span>

                            <div className="product-type-size-chart-image">
                                <img width={200} alt="logo" src={vendor?.product_type_size_charts[product_type]}/>
                            </div>

                            {uploadProductTypeSizeChartButton(product_type)}
                        </div>
                    )) }
                </div>
            </div>
        </div>
    );
};

export default EditsingleSupplier;