import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification, InputNumber, Modal, Upload, Button } from 'antd';
import ImagePicker from 'react-image-picker';

import usePrompt from "../../hooks/usePrompt";
import {
  CloseOutlined,
  PlusOutlined
} from "@ant-design/icons";

import "./NewColorProduct.css"
import { getBase64, uploadMedias, getTranslatedProductName } from "../../helpers"
import { fetchCreateProduct } from "../../redux/productSlice";
import { fetchVendor } from "../../redux/vendorSlice";

const NewColorProduct = ({
  isNew, 
  product_type, 
  series, 
  gender, 
  tags, 
  size_chart, 
  description_details, 
  cogs, 
  measurement, 
  status, 
  price, 
  vendor_product_group,
  onCreateColor,
  onFinish,
  visible
}) => {

  const { t } = useTranslation('translation');
  const { colors } = useSelector((state) => state.colors);
  const { vendor } = useSelector((state) => state.vendor);
  const { selectedLanguage } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  
  const dispatch = useDispatch()
  const { Option } = Select;
  const [form] = Form.useForm();
  const addNew = isNew
  const [product, setProduct] = useState(null);
  const [skuCode, setSkuCode] = useState("");
 
  const [isDirty, setIsDirty] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);



  usePrompt(t(`errors.You have unsaved changes, do you want to leave?`), isDirty);


  useEffect(() => {
    setSkuCode(createSKU());
  }, [ vendor,visible]); 

  useEffect(() => {
    setProduct(product => ({
      ...product,
      names : {...product?.names, tr : createSKU() + " - " + createNameWithoutSku(product?.names?.tr)}
    }))
  }, [skuCode]);

  const createSKU = () => {
      return `${vendor?.vendor_initial || ""}${(vendor?.vendor_product_count || 10000) + 1}`;
    
  }

  useEffect(() => {
      setProduct({
        tags: tags,
        status : status,
        gender: gender,
        product_type : product_type,
        series : series,
        size_chart : size_chart,
        description_details : description_details,
        cogs : cogs,
        price : price,
        measurement : measurement,
        vendor_product_group : vendor_product_group,
        color: [],
        
      });
  }, [form, tags, gender, product_type, series, size_chart, description_details, cogs, measurement, status]);


  const handleSubmitModal = async () => {
    setLoading(true);
    
    if(!!!product?.color?.length){
      notification['error']({
        message: t('errors.You need to select at least one color'),
      });
      setLoading(false)
      return
    }


    if(fileList.length === 0){
      notification['error']({
        message: t('errors.You need to upload at least one product image'),
      });
      setLoading(false)
      return
    }

    if ((product?.status === "Active" && vendor?.status === "Passive")) {
      notification['error']({
        message: t(`errors.You can only enter Passive products when the vendor is not Active!`),
      });
      setLoading(false);
      return;
    }

    const skuForUplaod =  skuCode;

    const selectedImageIndex = fileList.findIndex(file => file?.uid === selectedImage);

    let imageLinks = [];
    
    if(fileList?.filter(i => !i?.existBeforeUpdate)?.length) imageLinks = await uploadMedias(
      vendor?.name, 
      fileList?.filter(i => !i?.existBeforeUpdate), 
      skuForUplaod,
      fileList?.filter(i => i?.existBeforeUpdate)?.length
    );

    if(fileList.find(image => !image?.existBeforeUpdate)) {
      if (imageLinks.length === fileList?.filter(i => !i?.existBeforeUpdate).length) {
        notification['success']({
          message: t(`errors.All images uploaded successfully`),
        });
      } else {
        notification['error']({
          message: t(`errors.All images couldn't uploaded successfully`)
        });

      };
    }

    imageLinks = [...(fileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(imageLinks || [])];
    const main_image = imageLinks[selectedImageIndex];

    if(imageLinks?.length === 0) {
      notification['error']({
        message: t(`errors.You should specify images`)
      });
      setLoading(false);
      return;
    }

    if(main_image) {
      imageLinks = [main_image, ...imageLinks.filter(i => i !== main_image)]
    }

    let chartImage = "";
    
      let productEnterLog = {
        role: user?.role,
        user: user?.full_name,
        type: "New Product"
      }

      const request_product = {
        ...product,
        vendor,
        product_enter_log: productEnterLog
      }
      fetchCreateProduct(request_product, imageLinks, main_image, chartImage)
        .then((res) => {
          onCreateColor(res?.data)
          setLoading(false);
          setIsDirty(false);
          setProduct(null)
          setFileList([])
          form.resetFields();
          onFinish(false);
          dispatch(fetchVendor({ _id: vendor?._id}));
        })
        .catch((err) => {
          console.log(err)
          notification['error']({
            message: t(`errors.Product couldn't created successfully`),
          });
          setLoading(false);
        });
  };

  const handlePick = (image) => {
    setSelectedImage(image?.value)
    setIsDirty(true);
  }

  const deleteColor = (color) => {
    setProduct({ ...product, color: product.color.filter((item) => item._id !== color._id) });
    setIsDirty(true);
  };


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const createNameWithoutSku = (name) => {
    const dashArr = name?.split("-");

    if(dashArr?.length) {
      return dashArr?.slice(1)?.join("-")?.trimStart();
    }

    return "";
  }

  const createNameWithSku = (name) => {
    let sku = createSKU();
    return sku + " - " + capitalize(name);
  }

  const capitalize = (text) => {
    let words = text.split(" ");
    words = words.map(word => (word[0]?.toUpperCase() || "") + (word?.slice(1)?.toLocaleLowerCase() || ""));
    return words.join(" ");
  }

  return (
    <div className="modal-container" onClick={(e) => e.stopPropagation()}>
      
      <div className="modal-product-details">
        <Form
          form={form}
          className="modal-product-form"
          id = "modal-product-form"
          onFinish={handleSubmitModal}
          layout="vertical"
        >
          <div className="modal-product-header-save">
            <h2 className="modal-product-header">{getTranslatedProductName(product, selectedLanguage?.code)}</h2>
            <div>
              <Button type="primary" loading={loading} className="button-margin" htmlType="submit" style={{ marginRight: "10px", marginBottom: "10px" }}> 
                {t(`buttons.Save`)}
              </Button>
          </div>
          </div>
          <div className="modal-form-top">
            <div className="modal-form-left">
              <div className="modal-inline-inputs">
                <Form.Item label={t(`fields.product.Tr Name`)}>
                    <Input
                      value={product?.names?.tr}
                      disabled
                    />
                  </Form.Item>

                <Form.Item label={t(`fields.new_products.Raw Tr Name`)}>
                  <Input
                    value={createNameWithoutSku(product?.names?.tr)}
                    required
                    onChange={(e) => {
                      setProduct({ ...product, names : {...product.names, tr : createNameWithSku(e.target.value)}});
                      setIsDirty(true);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="modal-inline-inputs">
                {addNew && <Form.Item label={t(`fields.new_products.SKU Code`)}>
                  <Input
                    value={skuCode}
                    disabled
                  />
                </Form.Item>}
              </div>
              <Form.Item name="supplier_link" label={t(`fields.product.Supplier Link`)}>
                <Input
                  value={product?.supplier_link}
                  min={0}
                  onChange={(e) => {
                    const supplier_link = e.target.value
                    setProduct({ ...product, supplier_link });
                    setIsDirty(true);
                  }}
                  className="modal-input-width"
                />
              </Form.Item>
              <Form.Item name="supplier_stock_code" label={t(`fields.product.Supplier Stock Code`)}>
                <Input
                  value={product?.supplier_stock_code}
                  min={0}
                  onChange={(e) => {
                    const supplier_stock_code = e.target.value
                    setProduct({ ...product, supplier_stock_code });
                    setIsDirty(true);
                  }}
                  className="modal-input-width"
                />
              </Form.Item>

              <div className="modal-stock-box">  
                <Form.Item name="supplier_stock" label={t(`fields.product.Supplier Stock`)}>
                  <InputNumber
                    value={product?.supplier_stock}
                    min={0}
                    onChange={(e) => {
                      const supplier_stock = parseInt(e)
                      setProduct({ ...product, supplier_stock });
                      setIsDirty(true);
                    }}
                    className="modal-input-width"
                  />
                </Form.Item>
              </div>
             
          
            <div className="modal-inline-inputs">
              <Form.Item name="color" label={t(`fields.product.Colors`)}>
                <Select
                  onChange={(e) => {
                    const color = colors?.find(color => color._id === e)
                    form.setFieldsValue({ color: null });
                    if (!product?.color?.find(color => color._id === id)) {
                      setProduct({ ...product, color: [...product?.color, color] });
                    };
                    setIsDirty(true);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  placeholder={t(`placeholders.Add Color`)}
                >

                  {colors?.slice().sort((a, b) => a.color.localeCompare(b.color)).filter(item1 => !product?.color?.find(item2 => item1?.color === item2?.color)).map((color, index) => (
                    <Option key={'colors-select' + index} value={color._id}>
                      {selectedLanguage?.code === "tr" ? color?.names?.tr : (color?.names?.en || color?.color)}
                    </Option>
                  ))}
                </Select>
                <div className="modal-multi-container">
                  {product?.color?.length > 0 && product?.color?.map((color, index) => (
                    <div className="modal-multi-item" key={index}>
                      <CloseOutlined onClick={() => deleteColor(color)} />
                      {selectedLanguage?.code === "tr" ? color?.names?.tr : (color?.names?.en || color?.color)}
                    </div>
                  ))}
                </div>
              </Form.Item>
            </div>
            </div>
          </div>
          
          <div className="modal-form-bottom">
            <p>{t(`placeholders.Add Photos for Product`)}</p>

            <Upload
              listType="picture-card"
              fileList={fileList}
              multiple={true}
              onPreview={handlePreview}
              onChange={async ({ file: updatedFile, fileList: newFileList, file }) => {
                if(fileList.length < newFileList.length) {
                  const thumbUrl = await getBase64(file.originFileObj);
                  setFileList(currentList => [...currentList, { ...updatedFile, status: "done", thumbUrl }]);
                } else if (fileList.length > newFileList.length){
                  setFileList(newFileList.filter(f => f.status !== "removed"));
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div
                  className="modal-plus-outlined-margin"
                >
                  {t(`buttons.Upload`)}
                </div>
              </div>
            </Upload>

            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
              <img
                alt="example"
                className="modal-input-width"
                src={previewImage}
              />
            </Modal>
          </div>

          <div className="modal-form-bottom">
            <h3>{t(`fields.new_products.Images`)}</h3>
            <p>{t(`placeholders.Choose Main Image By Clicking`)}</p>
            {fileList?.length ? 
              <ImagePicker 
                images={fileList?.map((image, i) => {
                  return { src: image?.url || image?.thumbUrl, value: image?.uid }
                })}
                onPick={handlePick} 
              /> 
              : null
            }
          </div>

        </Form>
      </div >
    </div >
  )
};

export default NewColorProduct;
