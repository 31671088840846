import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Form, Button, List, Input, notification } from 'antd';
import { useTranslation } from "react-i18next";
import { fetchUpdate } from "../../redux/vendorSlice";

import "./EditUser.css";

const EditUser = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const { vendor } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.user);

    const [form] = Form.useForm();
    const [orderEmails, setOrderEmails] = useState([]);
    const [orderEmail, setOrderEmail] = useState("");

    useEffect(() => {
        setOrderEmails(user?.order_emails);
    }, [user]);

    const handleSubmit = async () => {
        fetchUpdate(vendor?._id, { order_emails: orderEmails })
            .then(() => {
                notification['success']({
                    message: t(`errors.Supplier updated successfully`)
                });
            })
            .catch(() => {
                notification['error']({
                    message:  t(`errors.Supplier updated successfully`)
                });
            })
    }

    return (
        <div className='edit-user'>
            <Form
                form={form}
                className="supplier-form"
                onFinish={handleSubmit}
                layout="vertical"
            >
                <div className="user-header-save">
                    <h2 className="user-header">{vendor?.name}</h2>
                    <div>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                            {t(`buttons.Save`)}
                        </Button>
                        <Button type="secondary" onClick={() => navigate(-1)}>
                            {t(`buttons.Cancel`)}
                        </Button>
                    </div>
                </div>

                <div className="saved-order-emails">
                    <div className="new-order-email">
                        <p>{t('fields.supplier_info.New Order Notification Email')}</p>
                        <Input.Group compact className="new-order-input">
                            <Input
                                style={{ width: 'calc(100% - 200px)' }}
                                placeholder={t('placeholders.Enter order notification email')}
                                value={orderEmail}
                                onChange={(e) => setOrderEmail(e.target.value)}>
                            </Input>

                            <Button 
                                type="primary"
                                disabled={!orderEmail} 
                                onClick={() => {
                                    if(orderEmail){
                                        setOrderEmails(e => [...e, orderEmail]);
                                        setOrderEmail("");
                                    }
                                    
                                }}
                            >
                                {t('buttons.Add')}
                            </Button>
                        </Input.Group>
                    </div>

                    <List
                        header={<div className="order-emails-header">{t('fields.supplier_info.Saved Order Notification Emails')}</div>}
                        bordered
                        dataSource={orderEmails}
                        renderItem={(item) => (
                            <List.Item>
                                <span>{item}</span>
                                <Button 
                                    type="secondary" 
                                    onClick={() => {
                                        setOrderEmails(es => es?.filter(e => e !== item));
                                    }}
                                >
                                    {t('home.Delete')}
                                </Button>
                            </List.Item>
                        )}
                    />
                </div>
            </Form>
        </div>
    );
};

export default EditUser;