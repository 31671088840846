import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviewCounts, fetchReviews, fetchTopVendors, setFilter } from "../../redux/reviewSlice";
import GeneralReviewInfo from "./components/GeneralReviewInfo";
import { calculateTotalReviewCount, calculateVendorScore } from "../../helpers";
import Review from "./components/Review";
import { fetchCountries } from "../../redux/countrySlice";
import "./Reviews.scss";
import { Select, Table, Tooltip } from "antd";
import { Pagination } from "../../components";
import { t } from "i18next";
import { EU_BIG_COUNTRIES } from "../../constants";
import { QuestionCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

const Reviews = () => {
    const dispatch = useDispatch();
    const { vendor } = useSelector((state) => state.vendor);
    const { reviews, filter, reviewCounts, topVendors } = useSelector((state) => state.review);
    const { selectedLanguage } = useSelector((s) => s.user);

    const [ countries, setCountries ] = useState([]);
    const [ euBigCountries, setEuBigCountries ] = useState([]);
    const [ euSmallCountries, setEuSmallCountries ] = useState([]);
    const [ reviewsInitial, setReviewsInitial ] = useState([]);
    const [ selectedStar, setSelectedStar ] = useState(0);
    const [ selectedCountry, setSelectedCountry ] = useState([]);
    const [ selectedCountries, setSelectedCountries ] = useState([]);
    const [ selectedRegion, setSelectedRegion ] = useState("");
    const [ scoresAndCounts, setScoresAndCounts ] = useState([]);
    const [ isHidden, setIsHidden ] = useState(true);


    const REVIEW_TOP_VENDORS_COLUMNS = [
        {
            title: (
                <span className="tooltip-text">
                  {t('review_product.Highest Score')}
                  <Tooltip title={t('review_product.Highest Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'highestScoreVendor',
            key: 'highestScoreVendor',
        },
        {
            title: (
                <span className="tooltip-text">
                  {t('review_product.Lowest Score')}
                  <Tooltip title={t('review_product.Lowest Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'lowestScoreVendor',
            key: 'lowestScoreVendor',
        },
        {
            title: (
                <span className="tooltip-text">
                  {t('review_product.Average Score')}
                  <Tooltip title={t('review_product.Mean Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'meanScore',
            key: 'meanScore',
        },
    ];

    useEffect(() => {
        if(vendor?._id) {
            let isHidden = true;
            if(!vendor?.authority_validity_values) {
                isHidden = true
            } else {
                isHidden = Object?.values(vendor?.authority_validity_values || [])?.some((validity) => !validity);
            }
            setIsHidden(isHidden)

            dispatch(fetchReviews({
                vendorId: vendor?._id,
                ...selectedStar !== 0 && {selectedStar: selectedStar },
                countryNames: selectedCountry,
                selectedCountries: selectedCountries,
                page: filter?.page,
                limit: filter?.limit,
                ...filter?.sort && { sort: filter?.sort }
            }));
    
        }
    }, [vendor?._id, selectedStar, selectedCountry, filter, selectedCountries]);

    useEffect(() => {
        setReviewsInitial(reviews?.reviews);
        setScoresAndCounts(reviews.scoresAndCounts?.[0]);
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [reviews]);

    useEffect(() => {
        fetchCountries().then((response) => {
            const countriesRaw = response;
            const countryNames = countriesRaw?.map(c => {
                return { label: c?.names?.[selectedLanguage?.code], value: c?.name }
            }).toSorted((a, b) => a?.label?.localeCompare(b?.label));

            countryNames?.unshift({ label: t('sort.all_countries'), value: "all" });
            setCountries(countryNames);

            setEuBigCountries(countriesRaw?.filter(c => c?.region === "Europe" && EU_BIG_COUNTRIES.includes(c?.name))?.map(c => c?.name));
            setEuSmallCountries(countriesRaw?.filter(c => c?.region === "Europe" && !EU_BIG_COUNTRIES.includes(c?.name))?.map(c => c?.name));
        });
    }, []);

    useEffect(() => {
        dispatch(fetchTopVendors({
            selectedCountries: selectedCountries,
            countryNames: selectedCountry,
        }));
    }, [selectedCountries, selectedCountry]);

    useEffect(() => {
        if(vendor?._id) {
            dispatch(fetchReviewCounts({
                vendorId: vendor?._id,
                countryNames: selectedCountry,
                selectedCountries: selectedCountries,
            }));
        }
    }, [vendor?._id, selectedCountries, selectedCountry])

    const handleFilter = (payload) => {
        dispatch(setFilter(payload));
    };

    const onRegionChange = (e) => {
        setSelectedRegion(e);
        if(e === "eu-big") {
            setSelectedCountries(euBigCountries);
            setSelectedCountry([]);
        } else if(e === "eu-small") {
            setSelectedCountry([]);
            setSelectedCountries(euSmallCountries);
        } else {
            setSelectedCountries([]);
        }
    }

    const onCountryChange = (checkedValues) => {
        if(checkedValues?.includes("all")) {
            setSelectedCountry([...countries, "all"]);
        } else if(selectedCountry?.includes("all")) {
            setSelectedCountry([]);
        } else {
            setSelectedCountry(checkedValues);
        }

        setSelectedCountries([]);
        setSelectedRegion("")
    }

    if(isHidden) {
        return null;
    }

    return (
        <div>
            <div className="review-top-vendors">
                <Table
                    title={() => (
                        <span>{
                            t('review_product.Top Vendors')}
                            <Tooltip title={t('review_product.Top Vendors Description')}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="top-vendors-table"
                    dataSource={topVendors}
                    columns={REVIEW_TOP_VENDORS_COLUMNS}
                    rowKey="name"
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                />
            </div>
            <div className="review-filter-container">
                <div className="single-filter">
                    <Select  
                        mode="multiple"
                        options={countries}
                        value={selectedCountry}
                        defaultValue="all"
                        showSearch
                        onChange={onCountryChange}
                        showArrow
                        placeholder={t('sort.all_countries')}
                        maxTagCount="responsive"
                        allowClear
                        filterOption={(input, option) =>  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                    />
                </div>

                <div className="single-filter">
                    <Select  
                        value={selectedRegion}
                        defaultValue=""
                        onChange={onRegionChange}
                    >
                        <Option key="all-eu" value="">{t('sort.all_europe_countries')}</Option>
                        <Option key="eu-big" value="eu-big">{t('sort.eu-big')}</Option>
                        <Option key="eu-small" value="eu-small">{t('sort.eu-small')}</Option>
                    </Select>
                    <Tooltip title={
                        <>
                            {t('review_product.EU Big Description')}
                            <br /><br />
                            {t('review_product.EU Small Description')}
                        </>
                    }>
                        <QuestionCircleOutlined  />
                    </Tooltip>
                </div>

                <div className="single-filter">
                    <Select  
                        defaultValue='{"createdAt":-1}'
                        onChange={(e) => handleFilter({...filter, sort: JSON.parse(e)})}
                    >
                        <Option value='{"createdAt":-1}'>{t('sort.Review Date')}: {t('sort.New to Old')}</Option>
                        <Option value='{"createdAt":1}'>{t('sort.Review Date')}: {t('sort.Old to New')}</Option>
                        <Option value='{"rating":-1}'>{t('sort.Score')}: {t('sort.High to Low')}</Option>
                        <Option value='{"rating":1}'>{t('sort.Score')}: {t('sort.Low to High')}</Option>
                    </Select>
                </div>
            </div>

            <div className="reviews-main-container">
                <GeneralReviewInfo starCounts={reviewCounts?.allCountsByRating} vendorScore={scoresAndCounts?.score} reviewCount={scoresAndCounts?.totalReviewCount} setSelectedStar={setSelectedStar}/>

                <div className="reviews-container">
                    {
                        reviewsInitial?.map((review) => (
                            <Review review={review}/>
                        ))
                    }
                </div>
            </div>
            
            <div>
                <Pagination filter={filter} setFilter={handleFilter} />
            </div>
        </div>
    )
}

export default Reviews;