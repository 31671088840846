import { Button, InputNumber, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./Pagination.css";

function Pagination({ filter, setFilter }) {
  const { t } = useTranslation('translation');

  const { Text } = Typography;
  const nextPage = () => setFilter({ ...filter, page: filter.page + 1 });
  const prevPage = () => setFilter({ ...filter, page: filter.page - 1 });
  const customPage = (e) => setFilter({ ...filter, page: Number(e.target.value) });
  const customLimit = (e) => setFilter({ ...filter, limit: Number(e.target.value) });

  return (
    <div className="pagination">
      <Space>
        <Button
          htmlType="button"
          disabled={filter.page <= 1}
          onClick={prevPage}
        >
          {"<"}
        </Button>
        <Text>{t(`home.Page`)}: </Text>
        <InputNumber min={1} onPressEnter={customPage} value={filter.page} />
        <Text>{t(`home.Limit`)}: </Text>
        <InputNumber
          min={1}
          onPressEnter={customLimit}
          value={filter.limit}
        />
        <Button htmlType="button" onClick={nextPage}>
          {">"}
        </Button>
      </Space>
    </div>
  );
}

export default Pagination;
