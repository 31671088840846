import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Form, Button, notification } from 'antd';
import { useTranslation } from "react-i18next";
import EditsingleSupplier from "./EditSingleSupplier";

import { fetchUpdate } from "../../redux/vendorSlice";
import { fetchUploadUrl } from "../../redux/productSlice";

import "./EditSupplier.scss";
import { useEffect } from "react";
import { uploadVendorBannerImage } from "../../helpers";

const EditSupplier = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const { vendor } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.user);

    const [form] = Form.useForm();
    const [vendors, setVendors] = useState([]);
    const [updatedVendors, setUpdatedVendors] = useState([]);

    useEffect(() => {
        if(vendor?._id && user) {
            setVendors([
                vendor,
                ...user?.additional_vendors
            ]);
        }
    }, [vendor, user]);

    const setSingleUpdatedVendor = (vendorName, updated) => {
        const index = updatedVendors?.findIndex(v => v?.name === vendorName);

        if(index === -1) {
            setUpdatedVendors([
                ...updatedVendors,
                updated
            ]);
            return;
        }

        const newUpdatedVendors = updatedVendors?.map((uv, uv_index) => {
            if(uv_index === index) {
                return updated;
            } else {
                return updatedVendors[uv_index];
            }
        })

        
        const newVendors = vendors?.map(v => {
            if(v?.name !== vendorName) {
                return v;
            } else {
                return {
                    ...(vendors?.find(v => v?.name === vendorName) || {}),
                    ...(updated || {})
                }
            }
        })

        setUpdatedVendors(newUpdatedVendors)
        setVendors(newVendors)
    }

    const handleSubmit = async () => {
        await Promise.all(updatedVendors?.map(async uv => {
            let updatedVendorCopy = { ...uv };

            let files = updatedVendorCopy?.files;
            let setFiles = updatedVendorCopy?.setFiles;
            let id = updatedVendorCopy?._id;
            let bannerFileList = updatedVendorCopy?.bannerFileList;

            delete updatedVendorCopy["files"];
            delete updatedVendorCopy["setFiles"];
            delete updatedVendorCopy["_id"];
            delete updatedVendorCopy["bannerFileList"];

            await Promise.all(files.map(async file => {
                const fileUrl = file?.productTypeSizeChart
                    ? `vendor/${updatedVendorCopy?.name?.replace(" ", "_")}/product_type_size_charts/${file?.type?.toLocaleLowerCase()}/${new Date().getTime()}.webp`
                    : `vendor/${updatedVendorCopy?.name?.replace(" ", "_")}/${file?.type?.toLocaleLowerCase()}/${new Date().getTime()}.webp`

                await fetchUploadUrl(fileUrl, "image/webp")
                    .then(async response => {
                        await fetch(response.data, {
                            method: "PUT",
                            body: file?.file,
                        })

                        const imageUrl = response.data.split('?')[0];
                        updatedVendorCopy[file?.type?.toLocaleLowerCase()] = imageUrl;

                        if (file?.productTypeSizeChart) {
                            updatedVendorCopy.product_type_size_charts[file?.type?.toLocaleLowerCase()] = imageUrl;
                        } else {
                            updatedVendorCopy[file?.type?.toLocaleLowerCase()] = imageUrl;
                        }
                    })
                    .catch(() => {
                        return;
                    })
            }));

            let newBannerImageLinks = [];
           
            const newBannerImageList = bannerFileList?.filter(f => !f?.existBeforeUpdate);
            const oldBannerImageList = bannerFileList?.filter(f => f?.existBeforeUpdate);
            const oldBannerImageLinkList = oldBannerImageList?.map(image => image?.url);
            let isMainInNewBannerImageLinks = newBannerImageList?.some(image => image?.is_main);

            if(newBannerImageList?.length > 0) {
                newBannerImageLinks = await uploadVendorBannerImage(updatedVendorCopy?.name, newBannerImageList);
            }
            
            if (bannerFileList.find(image => !image?.existBeforeUpdate)) {
                if (newBannerImageLinks.length === bannerFileList?.filter(i => !i?.existBeforeUpdate).length) {
                  notification['success']({
                    message: 'All images successfulluy uploaded to AWS!',
                  });
                } else {
                  notification['error']({
                    message: 'Error uploading images to AWS!',
                  });
                };
            }

            const mainBanner = (isMainInNewBannerImageLinks ? newBannerImageLinks?.[0] : oldBannerImageLinkList?.[0]) || "";

            newBannerImageLinks = [...(oldBannerImageLinkList || []), ...(newBannerImageLinks || [])];

            updatedVendorCopy.banner_images = newBannerImageLinks.sort((firstLink, secondLink) => firstLink === mainBanner ? -1 : secondLink === mainBanner ? 1 : 0);
            updatedVendorCopy.banner = mainBanner;

            setFiles([]);

            fetchUpdate(id, updatedVendorCopy)
                .then((res) => {
                    notification['success']({
                        message: updatedVendorCopy?.name + " - " + t(`errors.Supplier updated successfully`)
                    });
                })
                .catch(() => {
                    notification['error']({
                        message:  updatedVendorCopy?.name + " - " + t(`errors.Supplier couldn't be updated successfully`)
                    });
                })
        }))
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    }
    
    return(
        <div className='container'>
            <div className='product-details'>
                <Form
                    form={form}
                    className="supplier-form"
                    layout="vertical"
                >
                    <div className="header-save">
                        <div>
                            <Button type="primary" style={{ marginRight: "10px" }} onClick={handleSubmit}>
                                {t(`buttons.Save`)}
                            </Button>
                            <Button type="secondary" onClick={() => navigate(-1)}>
                                {t(`buttons.Cancel`)}
                            </Button>
                        </div>
                    </div>

                    {
                        vendors?.map((v, i) => (
                            <EditsingleSupplier vendor={v} index={i} form={form} setVendor={setSingleUpdatedVendor}/>
                        ))
                    }
                </Form>
            </div>
        </div>
    )
};

export default EditSupplier;