import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { separateAndCapitalize } from '../../helpers';

import './TagSelector.scss';

const TagSelector = ({ product, setProduct, setIsDirty }) => {

    const { t } = useTranslation();

    const { tags } = useSelector((state) => state.tags);
    const { filterTypes } = useSelector((state) => state.filterType);

    const [categorizedTags, setCategorizedTags] = useState([]);

    const categorizeTags = () => {
        return tags
            .filter(tag => {
                const type = tag.type;

                const filterType = filterTypes?.find(t => t?.type === type);

                const allowedProductTypes = filterType?.active_categories?.reduce((acc, crr) => {
                    if (crr?.product_type) {
                        acc.push(crr?.product_type)
                    } else {
                        const hasChildAllowedType = filterType?.active_categories?.find(c => c?.product_type && c?.parent_category?.toString() === crr?._id?.toString())
                        if (!hasChildAllowedType) acc = acc.concat(crr?.activeTypes?.map(t => t?._id));
                    }

                    return acc;
                }, []);

                return allowedProductTypes?.length > 0 ? allowedProductTypes.includes(product?.product_type?._id) : true;
            })
            .reduce((categories, tag) => {
                const type = tag.type;
                
                categories[type] = categories[type] || [];
                categories[type].push(tag);
                return categories;
            }, {});
    }

    useEffect(() => {
        if (tags?.length > 0 && filterTypes?.length > 0) setCategorizedTags(categorizeTags());
    }, [tags, filterTypes, product?.product_type]);

    const handleTagChange = (e) => {
        if (!product.tags.find((tag) => tag._id === e)) {
            setProduct({ ...product, tags: [...product.tags, tags.find((a) => a?._id === e)] });
        }
        setIsDirty(true);
    };

    const deleteTag = (tag) => {
        setProduct({ ...product, tags: product.tags.filter((item) => item._id !== tag._id) });
        setIsDirty(true);
    };

    const sortBasedOnType = (a, b) => a?.type?.localeCompare(b?.type);

    return (
        <div className='tag-selector-container'>
            <h2 className='tag-selector-header'>{t(`home.Tags`)}</h2>

            <div className='tag-selector'>
                <div className="multi-container-tag-selector">
                    {product?.tags?.sort(sortBasedOnType).map((tag, index, array) => (
                        <div className={`multi-item-tag-selector ${index > 0 && array[index - 1]?.type !== tag.type && "grouped"}`} key={index}>
                            { (tag?.type !== 'tag' && tag?.type !== 'price') && <CloseOutlined onClick={() => deleteTag(tag)} /> }
                            { tag?.names?.en }
                            <br />
                            <span className='multi-item-tag-type'>{separateAndCapitalize(tag?.type, '_')}</span>
                        </div>
                    ))}
                    {product?.tags?.length === 0 && <div>No tag</div>}

                </div>
                <Form.Item name="tags" className='multiple-tag-selection-container'>
                    <div className='multiple-tag-selection'>
                        {Object.keys(categorizedTags).sort().map((type) => (
                            <div className='tag-selector-select-container'>
                                <label>{separateAndCapitalize(type, '_')}</label>
                                <Select
                                    key={'select-' + type}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={handleTagChange}
                                    placeholder={`Add ${separateAndCapitalize(type, '_') === "Tag" ? "" : separateAndCapitalize(type, '_')} Tag`}
                                    className='tag-selector-select'
                                    disabled={type === 'tag' || type === "price"}
                                >
                                    {categorizedTags[type]
                                        ?.slice()
                                        .sort((a, b) => a?.names?.en?.localeCompare(b?.names?.en))
                                        .map((tag, index) => (
                                            <Select.Option key={'tag-select' + index} value={tag?._id}>
                                                {tag?.names?.en}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                        ))}
                    </div>

                </Form.Item>
            </div>
        </div>
    );
};

export default TagSelector;
