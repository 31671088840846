import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Products,
  Login,
  EditProduct,
  EditNewProduct,
  OrderProducts,
  NewProducts,
  EditSupplier,
  Coupons,
  EditCoupon,
  AllOrders,
  UsedCoupons,
  EditUser,
  Invoices,
  EditInvoice,
  StockOuts
} from './pages';
import { MainLayout, ScrollToTop } from './layouts';
import { setSelectedLanguage } from './redux/userSlice';
import { LANGUAGE_ROUTES, LANGUAGES } from './constants';

import './App.css';
import Reviews from './pages/Reviews/Reviews';
import SupplierAuthority from './pages/SupplierAuthority/SupplierAuthority';
import Returns from './pages/Returns/Returns';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('translation');
  const { selectedLanguage } = useSelector((s) => s.user);

  if (selectedLanguage?.code !== i18n.language) i18n.changeLanguage(selectedLanguage?.code);

  useEffect(() => {
    const urlLanguage = window?.location?.pathname?.split('/')[1];
    if (LANGUAGES.map((l) => l.code).includes(urlLanguage)
      && LANGUAGES.find((l) => l.code === urlLanguage)?.code !== selectedLanguage?.code) {
      dispatch(setSelectedLanguage(LANGUAGES.find((l) => l.code === urlLanguage)));
    }
  }, [dispatch, selectedLanguage]);

  return (
    <Router>
      <ScrollToTop>
        {LANGUAGE_ROUTES.map((route) => (
          <div className="App">
            <Routes>

              <Route path={`${route}/login`} element={<Login />} />

              <Route path={route} element={<MainLayout />}>
                <Route path="products" element={<Products />} />
                <Route path="product/:handle" element={<EditProduct />} />
                <Route path="invoice/:handle" element={<EditInvoice />} />
                <Route path="order-products" element={<OrderProducts type="order_products"/>} />
                <Route path="late-products" element={<OrderProducts type="late_products"/>} />
                <Route path="new-products" element={<NewProducts />} />
                <Route path="new-product/:id" element={<EditNewProduct />} />
                <Route path="supplier/" element={<EditSupplier />} />
                <Route path="user/" element={<EditUser />} />
                <Route path="invoices" element={<Invoices />} />
                <Route path="coupons" element={<Coupons />} />
                <Route path="coupon/:id" element={<EditCoupon />} />
                <Route path="all-orders" element={<AllOrders />} />
                <Route path="used-coupons" element={<UsedCoupons />} />
                <Route path="reviews" element={<Reviews />} />
                <Route path="supplier-authority" element={<SupplierAuthority />} />
                <Route path="stockouts" element={<StockOuts />} />
                <Route path="returns" element={<Returns />} />
              </Route>
            </Routes>
          </div>
        ))}
      </ScrollToTop>
    </Router>
  );
}

export default App;
