import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchAllProductTypes = createAsyncThunk(
  'productTypes/fetchAllProductTypes',
  async () => {
    const { data } = await axios.get(`${url}/product_type/all`);
    return data;
  },
);

export const fetchDelete = (id) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/product_type/one/${id}`);

const productTypeSlice = createSlice({
  name: 'productTypes',
  initialState: {
    filter: {},
    allProductTypes: [],
    status: {
      fetchAllProductTypes: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // get all
    [fetchAllProductTypes.pending]: (state) => {
      state.status.fetchAllProductTypes = 'pending';
    },
    [fetchAllProductTypes.fulfilled]: (state, action) => {
      state.status.fetchAllProductTypes = 'succeeded';
      state.allProductTypes = action.payload;
    },
    [fetchAllProductTypes.rejected]: (state, action) => {
      state.status.fetchAllProductTypes = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = productTypeSlice.actions;

export default productTypeSlice.reducer;
