import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Input, Select, Spin, DatePicker, Button, Checkbox} from 'antd';
import { Pagination } from '../../components';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSVLink } from "react-csv";
import axios from "axios";


import {
    calculateItemPrice,
    loncaRound,
    loncaFormatDate,
    calculateVendorCogs,
    renderCalculatedVendorCogs,
    renderLoncaVendorCurrency
} from '../../helpers';

import { fetchOrderAggregate } from '../../redux/orderSlice';

import "./AllOrders.css";

import LocaleProvider from 'antd/lib/locale-provider';
import tr_locale from "antd/es/date-picker/locale/tr_TR"; 
import 'moment/locale/tr';
import TextArea from "antd/lib/input/TextArea";

const AllOrders = () => {
    const { t } = useTranslation('translation');

    const { vendor } = useSelector((state) => state.vendor);
    const { selectedLanguage, languagePath, user } = useSelector((state) => state.user);

    const [orders, setOrders] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(-1);
    const [pagination, setPagination] = useState({ page: 1, limit: 100 });
    const [searchText, setSearchText] = useState('');
    const [searchRealText, setRealSearchText] = useState('');
    const [statusFilter, setStatusfFilter] = useState('');
    const [dateFilter, setDatefFilter] = useState({ start: null, end: null });
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [showDate,setShowDate] = useState(false)
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const csvInstance = useRef();

    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const handleExport = async () => {

        setExportLoading(true);
        
            const headers = [
              { label: "Sipariş Numarası", key: "unique_order_id" },
              { label: "Tedarikçi Stok Kodu", key: "product_name" },
              { label: "Ürün Paket Barkodu", key: "product_misfire_barcode" },
              { label: "Lonca Ürün Kodu", key: "product_code" },
              { label: "Toptancı", key: "vendor" },
              { label: "Toptancı Ürün Linki", key: "supplier_link" },
              { label: "Sipariş Tarihi", key: "order_date" },
              { label: "Sipariş Durumu", key: "order_status" },
              { label: "Ürün Sayısı", key: "item_count" },
              { label: "Paket Sayısı", key: "quantity" },
              { label: "Paket", key: "product_series" },
              { label: "Adet Fiyatı (KDV Hariç)", key: "product_unit_price_without_vat" },
              { label: "Adet Fiyatı (KDV Dahil)", key: "product_unit_price_with_vat" },
              { label: "Toplam Fiyat (KDV Dahil)", key: "product_total_price" },
              { label: "Kur Bilgisi", key: "order_currency_rate" },
              { label: "Kullanılan Kupon", key: "used_coupon" },
              { label: "İndirim Miktarı", key: "discount" },
            ];
        
            setExportHeaders(headers);
            const data = orders.map(item => {
                const cogs = calculateVendorCogs({
                    payment_at: item?.payment_at, 
                    cogs: item?.cart_item?.cogs, 
                    rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                    rate_usd_buying: item?.rate_usd_buying, 
                    rate_usd_selling: item?.rate_usd_selling, 
                  })
             return {
                unique_order_id: item?.unique_order_id,
                product_name: item?.cart_item?.supplier_stock_code || (selectedLanguage?.code === "tr" ? item?.cart_item?.product_name_tr : item?.cart_item?.product_name),
                product_code: item?.populatedProduct?.product_code,
                vendor: item?.cart_item?.vendor?.name,
                supplier_link: item?.cart_item?.supplier_link || "",
                order_date: loncaFormatDate(item?.payment_at),
                order_status: item?.cart_item?.order_status,
                quantity: item?.cart_item?.quantity,
                item_count: item?.cart_item?.item_count,
                product_series: item?.cart_item?.series,
                product_misfire_barcode: item?.cart_item?.vendor_product_id?.[item?.cart_item?.series],
                product_unit_price_without_vat: renderCalculatedVendorCogs({
                                                    payment_at: item?.payment_at, 
                                                    cogs: item?.cart_item?.cogs, 
                                                    rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                                                    rate_usd_buying: item?.rate_usd_buying, 
                                                    rate_usd_selling: item?.rate_usd_selling, 
                                                }),
                product_unit_price_with_vat: `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${loncaRound(calculateItemPrice({
                  item: {
                    ...item, 
                    product: { 
                      product_type: { vat: item?.cart_item?.vat } 
                    }, 
                    cogs,
                  }, order: true}) 
                )}`,
                product_total_price: `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${loncaRound(calculateItemPrice({
                  item: {
                    ...item, 
                    product: { 
                      product_type: { vat: item?.cart_item?.vat } 
                    }, 
                    cogs,
                  }, 
                  order: true
                  }
                )  * item?.cart_item?.item_count * item?.cart_item?.quantity)}`,
                order_currency_rate: item?.rate_usd_tr_selling?.toString() || "",
                used_coupon: item?.coupon?.code || "",
                discount: item?.coupon && item?.coupon?.discount_type === "Percentage" ? 
                  `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${Math.round((calculateItemPrice({
                    item: {
                      ...item, 
                      product: { 
                        product_type: { vat: item?.cart_item?.vat } 
                      }, 
                      cogs,
                    }, 
                    order: true}
                  ) * item?.cart_item?.item_count * item?.cart_item?.quantity) * (item?.coupon?.discount_amount) * 10) / 10}` : "",
              }
        
            });
        
            setExportData(data);
    
            setExportLoading(false);
    
      }

    useEffect(() => {
        if (exportData?.length && csvInstance.current && csvInstance.current.link) {
          setTimeout(() => {
            csvInstance.current.link.click();
            setExportData(false);
          });
        }
      }, [exportData]);

    useEffect(() => {
        if(vendor?._id && user) {
            setSelectedVendors([
                vendor?.name,
                ...(user?.additional_vendors?.map(v => (v?.name)) || [])
            ]);
        }
    }, [vendor, user]);

    useEffect(() => {
        // Get the input field
        var input = document.getElementById("search-input");

        // Execute a function when the user presses a key on the keyboard
        input.addEventListener("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            document.getElementById("search-submit-button").click();
        }
        });
    }, [])

    useEffect(() => {
        setLoading(true);

        const source = axios.CancelToken.source();
        
        if(vendor?._id) fetchOrderAggregate({
            filter: [
                dateFilter, 
                searchRealText,
                selectedVendors,
                statusFilter, 
                sort, 
                pagination
            ], 
            mode: "all_orders",
            cancelToken: source.token
        })
          .then((res) => {
            setOrders(res.data);
            setLoading(false);
          })
          .catch((err) => console.log(err));

        return () => {
            source.cancel('Operation canceled by the user.');
        }

    }, [sort, pagination?.page, pagination?.limit, statusFilter, vendor, searchRealText, dateFilter, selectedVendors]);



    useEffect(() => {

        const cartItemMap = orders.map((item) => {
            item.coupon = item?.coupon?.vendor === vendor?._id ? item?.coupon : null
            return item;
        })

        setCartItems(cartItemMap)
        
    }, [orders, searchText, vendor])

    return(
        <div>
            <div>
                <div className='top-bar'>
                    <h2>{t(`home.All Orders`)}</h2>
                    <div className='top-btns'>
    
                    <Button className='discard-btn' type='secondary' loading={exportLoading} onClick={handleExport}>{t(`buttons.Export CSV`)}</Button>
                <CSVLink
                    data={exportData || []}
                    headers={exportHeaders || []}
                    filename={`lonca_tum_urunler_${loncaFormatDate(new Date())}.csv`}
                    ref={csvInstance}
                />
            </div>
                </div>
                <div className="form-label-bar">
                <div className='form-bar-select'>
                        {t(`sort.Sort`)}
                    </div>
                </div>
                <div className="form-bar">
                <div className="search-line">
                    <Input className='all-search-bar' id="search-input" placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
                    <Button 
                        id="search-submit-button"
                        type="primary" 
                        onClick={() => setRealSearchText(searchText)}
                    >
                        Search
                    </Button>
                </div>
                <Select className='form-bar-select' placeholder='Sort' defaultValue={-1} onChange={setSort}>
                        <Option key='new-to-old' value={-1}>{t(`sort.New to Old`)}</Option>
                        <Option key='old-to-new' value={1}>{t(`sort.Old to New`)}</Option>
                    </Select>
                </div>

                <div className='all-form-label-bar'>
                    { user?.additional_vendors?.length > 0 && <div className='form-bar-select'>
                        {t(`home.Vendor`)}
                    </div> }
                    <div className='form-bar-select' style={user?.additional_vendors?.length > 0 ? {width: "50%"} : {width: "70%"}}>
                   
                            <p className="date-checkbox">
								<Checkbox checked={showDate} onChange={(e) => setShowDate(e.target.checked)}>
									{t(`placeholders.I want to specify date interval`)}
								</Checkbox>
							</p>
                    
                    </div>

                </div>  

                <div className='all-form-bar'>
                    { user?.additional_vendors?.length > 0 && 
                        <Select
                            onChange={(e) => {
                                if(e === "all") {
                                    setSelectedVendors([
                                        vendor?.name,
                                        ...(user?.additional_vendors?.map(v => (v?.name)) || [])
                                    ]);
                                    return
                                }
                                setSelectedVendors([ e ]);
                            }}
                            className='form-bar-select'
                            showSearch
                            optionFilterProp="children"
                            defaultValue={'all'}
                            filterOption={(input, option) =>
                                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                            }
                        >
                            <Option value="all">{t(`home.All Vendors`)}</Option>
                            <Option value={vendor?.name}>{vendor?.name}</Option>
                            {user?.additional_vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                                <Option key={'vendor-select' + index} value={vendor.name}>
                                    {vendor.name}
                                </Option>
                            ))}
                        </Select> 
                    }
					{ showDate ? 
                    <LocaleProvider className="date-picker">
                        <RangePicker
                            locale={languagePath === "" ? tr_locale : null}	
                            showTime
                            value={[moment(dateFilter?.start || new Date()), moment(dateFilter?.end || new Date())]}
                            onChange={([start, end]) => {
                                setDatefFilter({start: new Date(start._d), end: new Date(end._d)})
                            }}
                            style={user?.additional_vendors?.length > 0 ? {width: "50%"} : {width: "70%"}}
                        />
                    </LocaleProvider> : <div/>}

                 </div>
            </div>

            <div className='product-table'>
                {loading && <Spin tip={t(`placeholders.Loading Products...`)} />}

                {cartItems?.map((item, index) => {
                    const unitPrice = calculateItemPrice({item : {
                        ...item, 
                        cogs: calculateVendorCogs({
                            payment_at: item?.payment_at, 
                            cogs: item?.cart_item?.cogs, 
                            rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                            rate_usd_buying: item?.rate_usd_buying, 
                            rate_usd_selling: item?.rate_usd_selling, 
                          }),
                        product: { product_type: { vat: item?.cart_item?.vat } }
                    }, order: true});

                    const totalPrice = loncaRound(unitPrice * item?.cart_item?.item_count * item?.cart_item?.quantity);
                    const itemDate = item?.cart_item?.order_addition_date ? item?.cart_item?.order_addition_date : item?.payment_at;

                    return (
                        <div className={`product-container all-product-container`} key={item._id + index} item={item}>
                            <div className="item-image">
                                <img className='product-image' src={item?.cart_item?.product_image} alt="product"></img>
                            </div>

                            <div className="all-item-info">
                                <div className="all-info-row">
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.product.unique_id`)}</span>
                                        <Input disabled value={item?.unique_order_id}
                                        />
                                    </div>
                                    {item?.external_order_ids?.[item?.cart_item?.vendor_name?.toLowerCase()]?.[0] && 
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.product.supplier_unique_id`)}</span>
                                        <input className={`ant-input`} value={item?.external_order_ids?.[item?.cart_item?.vendor_name?.toLowerCase()]?.[0]} disabled />
                                    </div>}
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.Payment At`)}</span>
                                        <Input disabled value={loncaFormatDate(itemDate)}
                                        />
                                    </div>
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.Notes`)}</span>
                                        <TextArea 
                                            value={item?.cart_item?.notes}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="all-info-row">

                                <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.product.Lonca Name`)}</span>
                                        <Input value={selectedLanguage?.code === "tr" ? item?.cart_item?.product_name_tr : item?.cart_item?.product_name} disabled />
                                    </div>
                                    <div className='all-product-info-item'>
                                            <span className='product-info-label'>{t(`fields.product.Product Name`)}</span>
                                            <Input disabled value={item?.cart_item?.supplier_stock_code}
                                            />
                                        </div>
                                </div>
                                <div className="all-info-row">
                                        {item?.cart_item?.vendor_product_id?.[item?.cart_item?.series] > 0 && <div className='all-product-info-item'>
                                            <span className='product-info-label'>{t(`fields.product.Product Series Barcode`)}</span>
                                            <Input value={item?.cart_item?.vendor_product_id?.[item?.cart_item?.series]} disabled />
                                        </div>}
                                        <div className='all-product-info-item'>
                                            <span className='product-info-label'>{t(`fields.product.Series`)}</span>
                                            <Input disabled value={item?.cart_item?.series}
                                            />
                                        </div>
                                        <div className='all-product-info-item'>
                                            <span className='product-info-label'>{t(`fields.order_products.Quantity`)}</span>
                                            <Input disabled value={item?.cart_item?.quantity}
                                            />
                                        </div>
                                </div>

                                <div className="all-info-row">
                                <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.COGS`)}</span>
                                        {<input 
                                        className={`ant-input`} 
                                        value={renderCalculatedVendorCogs({
                                            payment_at: item?.payment_at, 
                                            cogs: item?.cart_item?.cogs, 
                                            rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                                            rate_usd_buying: item?.rate_usd_buying, 
                                            rate_usd_selling: item?.rate_usd_selling, 
                                            })} 
                                        disabled />}
                                    </div>
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.Unit Price (Including VAT)`)}</span>
                                        <input className='ant-input' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${unitPrice}`} disabled/>
                                    </div>
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.Total Price (Including VAT)`)}</span>
                                        <input className='ant-input' placeholder='Item Count' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${totalPrice}`} disabled/>
                                    </div>
                                </div>

                                <div className="all-info-row">
                                    <div className='all-product-info-item'>
                                        <span className='product-info-label'>{t(`fields.order_products.Rate Usd Tr Buying`)}</span>
                                        {<input className='ant-input' placeholder='Rate Usd Tr' value={renderLoncaVendorCurrency(item)} disabled />}
                                    </div>
                                </div>

                                

                            </div>
                        </div>
                    )
                })}

                <Pagination filter={pagination} setFilter={setPagination} />
            </div>
        </div>
    )
}

export default AllOrders;