import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthorityConfigs, fetchStockOuts } from "../../redux/stockOutSlice";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip, Spin } from "antd";
import { t } from "i18next";
import "./StockOuts.scss";

const StockOuts = () => {
    const dispatch = useDispatch();
    const { vendor, status: { fetchVendor: vendorStatus } } = useSelector((state) => state.vendor);
    const { stockOuts, authorityConfigs,  status: { fetchStockOuts: stockOutsStatus }  } = useSelector((state) => state.stockout);
    const [ vendorStockOuts, setVendorStockOuts ] = useState({});
    const [ stockOutConfig, setStockOutConfig ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ isHidden, setIsHidden ] = useState(true);

    useEffect(() => {
        dispatch(fetchStockOuts());
        dispatch(fetchAuthorityConfigs());
    }, []);

    useEffect(() => {
        if(vendor?._id) {
            let isHidden = true;
            if(!vendor?.authority_validity_values) {
                isHidden = true
            } else {
                isHidden = Object?.values(vendor?.authority_validity_values || [])?.some((validity) => !validity);
            }
            setIsHidden(isHidden)
            setVendorStockOuts({
                stockOut: vendor?.authority_details?.stockout_amount,
                total: vendor?.authority_details?.stockout_total_amount,
                stockOutQuantityValue: vendor?.authority_details?.stockout
            });
            /* setLoading(false); */
        }
    }, [vendor?._id]);

    useEffect(() => {
        if(authorityConfigs?.length > 0) {
            const config = authorityConfigs?.find(config => config?.key === "stockout");
            setStockOutConfig(config);
        }
    }, [authorityConfigs]);

    useEffect(() => {
        if(stockOutsStatus === "fulfilled" && vendorStatus === "succeeded") {
            setLoading(false);
        }
    }, [stockOutsStatus, vendorStatus])

    const formatStockOutValues = (stockOuts) => {
        return [
          { key: '1', metric: 'stockOutQuantityValue', max: stockOuts?.maxStockOutValue?.stockOutQuantityValue, min: stockOuts?.minStockOutValue?.stockOutQuantityValue, mean: stockOuts?.meanStockOutValue?.stockOutQuantityValue, },
        ];
    };

    const formatVendorStockOutValues = (stockOuts) => {
        return [
            { key: '1', metric: 'stockOut', stockout: stockOuts?.stockOut, total: stockOuts?.total, stockOutQuantityValue: stockOuts?.stockOutQuantityValue},
        ];
    }

    const STOCKOUT_TOP_VENDORS_COLUMNS = [
        {
            title: '',
            dataIndex: 'metric',
            key: 'metric',
            fixed: 'left',
            render: (text) => (
                <span>{t(`stockouts.${text}`)}</span>
              ),
        },
        {
            title: (
                <span>
                  {t('stockouts.Highest Score')}
                  <Tooltip title={t('stockouts.Highest Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'max',
            key: 'max',
            render: (text) => loading ? <Spin /> : `${text}%`
        },
        {
            title: (
                <span>
                  {t('stockouts.Lowest Score')}
                  <Tooltip title={t('stockouts.Lowest Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'min',
            key: 'min',
            render: (text) => loading ? <Spin /> : `${text}%`
        },
        {
            title: (
                <span>
                  {t('stockouts.Mean Score')}
                  <Tooltip title={t('stockouts.Mean Score Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'mean',
            key: 'mean',
            render: (text) => loading ? <Spin /> : `${text}%`
        },
    ];

    const STOCKOUT_COLUMNS = [
        {
            title: (
                <span>
                    {t("stockouts.stockOut")}
                    <Tooltip title={t("stockouts.stockOut Description")} />
                </span>
            ),
            dataIndex: 'stockout', 
            key: 'stockout',
            render: (text) => loading ? <Spin /> : text
        },
        {
            title: (
                <span>
                    {t("stockouts.total")}
                    <Tooltip title={t("stockouts.total Description")} />
                </span>
            ), 
            dataIndex: 'total',
            key: 'total',
            render: (text) => loading ? <Spin /> : text
        },
        {
            title: (
                <span>
                    {t("stockouts.stockOutQuantityValue")}
                </span>
            ), 
            dataIndex: 'stockOutQuantityValue',
            key: 'stockOutQuantityValue',
            render: (text) => loading ? <Spin /> : `${text}%`
        },
    ];

    if(isHidden) {
        return null;
    }


    return (
        
        <div>
            <h2 className="stockouts-header">{t('home.StockOuts')}</h2>

            <div className="stockouts">
                <Table
                    title={() => (
                        <span>{
                            t('stockouts.Top Vendors')}
                            <Tooltip title={t('stockouts.Top Vendors Description')}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="stockouts-table"
                    columns={STOCKOUT_TOP_VENDORS_COLUMNS}
                    dataSource={formatStockOutValues(stockOuts)}
                    rowKey="name"
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                />
            </div>

            <div className="stockouts">
                <Table
                    title={() => (
                        <span>{
                            t('stockouts.My Score')}
                            <Tooltip title={t('stockouts.My Score Description', { value: stockOutConfig?.last_months })}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="stockouts-table"
                    columns={STOCKOUT_COLUMNS}
                    dataSource={formatVendorStockOutValues(vendorStockOuts)}
                    rowKey="name"
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                />
            </div>
        </div>

        
    )
}

export default StockOuts;