import { Rate } from "antd";
import React, { useState } from "react";
import "../Reviews.scss";
import { loncaRound } from "../../../helpers";

const GeneralReviewInfo = ({ starCounts, vendorScore, reviewCount, setSelectedStar }) => {
    const [ratings, setRatings] = useState([5, 4, 3, 2, 1]);
    const [selectedRating, setSelectedRating] = useState(-1);

    const onChangeRating = (rating, index) => {
        if(index === selectedRating || index === -1) {
            setSelectedRating(-1);
            setSelectedStar(0);
        } else {
            setSelectedStar(rating);
            setSelectedRating(index);
        }
    }

    return (
        <div className="general-review-info-main-container">

            <div className="vendor-total-rating" onClick={() => onChangeRating(_, -1)}>
                <span >{loncaRound(vendorScore, 1) || 0}</span>
                <Rate 
                    className="review-star"
                    value={vendorScore}
                    allowHalf
                    disabled
                />
                <span>{reviewCount || 0}</span>
            </div>

            <div className="vendor-all-ratings">
                {ratings.map((rating, index) => (
                    <div className={`single-rating ${selectedRating === index ? "selected-single-rating" : ""}`} key={index} onClick={() => onChangeRating(rating, index)}>
                        <span>{rating}</span>
                        <Rate
                            className="review-star"
                            value={rating}
                            disabled
                        />
                        <span>{starCounts?.[`${rating}`]}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GeneralReviewInfo;