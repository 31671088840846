import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Image } from 'antd';

import './Product.css';
import { defaultImage, loncaRound, getTranslatedProductName, getTranslatedFieldName} from '../../helpers';

function Product({ item }) {
  const { t } = useTranslation('translation');
  const { languagePath, selectedLanguage } = useSelector((state) => state.user);

  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  if (!item) return null;

  return (
    <div className="list-product">
      <div 
        className="list-product-item"
        onClick={() => navigate(`${languagePath}/product/${item.handle}`)}
      >
        <div className="list-product-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-product-item-image" onClick={(e) => e.stopPropagation()}>
          <Image height={60} src={item.main_image || defaultImage} />
        </div>
        <div className="list-product-item-product">{ getTranslatedProductName(item,selectedLanguage?.code)}</div>
        <div className="list-product-item-status"> { t(`status.${item.status}`) }</div>
        <div className="list-product-item-type">{getTranslatedFieldName(item.product_type, selectedLanguage?.code)}</div>
        <div className="list-product-item-tag">{getTranslatedFieldName(item.tags[0], selectedLanguage?.code)}</div>
        <div className="list-product-item-vendor">{item.vendor?.name}</div>
        <div className="list-product-item-price">${loncaRound(item?.price)}</div>
        <div className="list-product-item-code">{item?.supplier_stock_code}</div>
      </div>
    </div>
  );
}

export default Product;
