import React, { useState } from 'react';
import { Button, Upload, Modal } from 'antd';
import { PlusOutlined, CheckCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import './ImageBox.css';
import { getBase64 } from '../../helpers';

export const ImageUploader = ({
  fileList,
  product,
  handlePreview,
  setFileList,
  getBase64,
  previewVisible,
  previewTitle,
  previewImage,
  previewType,
  setPreviewVisible,
  isEditable = true,
  nonEditableTitle
}) => {

    return (
        <div className="">
            {isEditable ?
                <div style={{"position": "relative"}}>
                    <p>Add Photos for Product</p>
                    <p>To select main image or delete an image, hover the cursor over the product and use buttons</p>
                </div> :
                <p>{nonEditableTitle}</p>
            }
            
            <div style={{marginTop: "10px"}}>
                <Upload
                    listType="picture-card"
                    multiple={true}
                    fileList={fileList}
                    onPreview={handlePreview}
                    itemRender={(originNode, file, fileList, { remove }) => {
                    return (
                        <div className={`single-image ${file?.is_main ? "main-img" : ""}`}>
                        <img width={86} height={86} src={file.url} alt={file.uid} />
                        <div className="shadow-img" onClick={(e) => {handlePreview(file)}}>
                            {isEditable && 
                                <CheckCircleOutlined className="main-selector" 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setFileList(list => list.map(f => {
                                            return {
                                            ...f,
                                            is_main: f.uid === file.uid
                                            }
                                        }));
                                    }} 
                                />
                            }
                            {isEditable &&
                                <DeleteOutlined className="trash-selector" 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        remove();
                                    }} 
                                />
                            }
                        </div>
                        </div>
                    )
                    }}
                    onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                        if (fileList.length < newFileList.length) {
                            const url = await getBase64(updatedFile.originFileObj)
                            setFileList(currentList => [...currentList, { ...updatedFile, status: "done", url, is_main: currentList.length === 0 }]);
                        } else if (fileList.length > newFileList.length) {
                            let updatedList = newFileList.filter(f => f.status !== "removed");

                            if (updatedList?.length > 0 && updatedFile?.is_main) {
                                updatedList = [{ ...updatedList[0], is_main: true }, ...updatedList.slice(1)]
                            }

                            setFileList(updatedList);
                        }
                    }}
                >
                    {isEditable && <div>
                        <PlusOutlined />
                        <div
                            className="plus-outlined-margin"
                        >
                            Upload
                        </div>
                    </div>}
                </Upload>
            </div>
            

            <Modal visible={previewVisible && previewType === 'image'} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img
                alt="example"
                className="input-width"
                src={previewImage}
                />
            </Modal>

        </div>
    );
};

export const ImageUploaderForVendorBanner = ({
    fileList,
    setFileList,
    handlePreview,
    previewVisible,
    previewTitle,
    previewImage,
    setPreviewVisible
}) => {
    return (
        <>
            <div style={{"position": "relative", marginBottom: "10px"}}>
                <h3>Add Banners for Vendor</h3>
                <span>To select main image or delete an image, hover the cursor over the product and use buttons</span>
            </div> 
            
            <Upload 
                listType='picture-card' 
                fileList={fileList}
                onPreview={handlePreview}
                itemRender={(originNode, file, fileList, { remove }) => {
                    return (
                        <div className={`vendor-banner-single-image ${file?.is_main ? "vendor-banner-main-image" : ""}`}>
                            <img width={86} height={86} src={file.url} alt={file.uid} />

                            <div className='vendor-banner-shadow-image' onClick={(e) => handlePreview(file)}>
                                <CheckCircleOutlined 
                                    className='vendor-banner-main-selector'
                                    onClick={() => {
                                        const newFileList = fileList.map(f => {
                                            return {
                                                ...f,
                                                is_main: f.uid === file.uid
                                            }
                                        }).sort((file1, file2) => file1?.is_main ? -1 : file2?.is_main ? 1 : 0 )
                                        setFileList(newFileList);
                                    }} 
                                />
                                <DeleteOutlined 
                                    className='vendor-banner-trash-selector'
                                    onClick={() => {
                                        remove();
                                    }}
                                />
                            </div>
                        </div>
                    )
                }}
                onChange={async ({file: updatedFile, fileList: newFileList}) => {
                    if (fileList.length < newFileList.length) {
                        const url = await getBase64(updatedFile.originFileObj);
                        setFileList([...newFileList.slice(0, newFileList.length - 1), { ...newFileList[newFileList.length - 1], url: url, status: "done", is_main: fileList.length === 0}]);
                    } else if (fileList.length > newFileList.length) {
                        let updatedList = newFileList.filter(f => f.status !== "removed");
                        if (updatedList?.length > 0 && updatedFile?.is_main) {
                            updatedList = [{ ...updatedList[0], is_main: true }, ...updatedList.slice(1)]
                        }

                        setFileList(updatedList);
                    }
                    
                }}
            >
                <div>
                    <UploadOutlined />
                    <div>
                        Upload
                    </div>
                </div>
            </Upload>

            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img
                    alt="example"
                    className="input-width"
                    src={previewImage}
                />
            </Modal>
        </>
    )
}