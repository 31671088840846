import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchTopScores = createAsyncThunk('supplier-authority/fetchTopScores', async () => {
    const result = await axios.get(`${url}/supplier-authority/top-scores`);
    return result?.data;
});

export const fetchAuthorityConfig = createAsyncThunk('supplier-authority/fetchAuthorityConfig', async () => {
    const result = await axios.get(`${url}/supplier-authority/get-configs`);
    return result?.data;
});

const supplierAuthoritySlice = createSlice({
    name: 'authority',
    initialState: {
        topScores: [],
        authorityConfig: [],
        status: {
            fetchTopScores,
            fetchAuthorityConfig: []
        },
        error: null,
    },
    reducers: { },
    extraReducers: {
        [fetchTopScores.pending]: (state) => {
            state.status.fetchTopScores = 'pending';
        },
        [fetchTopScores.fulfilled]: (state, action) => {
            state.status.fetchTopScores = 'fulfilled';
            state.topScores = action.payload;
        },
        [fetchTopScores.rejected]: (state, action) => {
            state.status.fetchTopScores = 'rejected';
            state.error = action.error.message;
        },

        [fetchAuthorityConfig.pending]: (state) => {
            state.status.fetchAuthorityConfig = 'pending';
        },
        [fetchAuthorityConfig.fulfilled]: (state, action) => {
            state.status.fetchAuthorityConfig = 'fulfilled';
            state.authorityConfig = action.payload;
        },
        [fetchAuthorityConfig.rejected]: (state, action) => {
            state.status.fetchAuthorityConfig = 'rejected';
            state.error = action.error.message;
        },
    },
});

export default supplierAuthoritySlice.reducer;