import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchFilterTypes = createAsyncThunk('tag/fetchFilterTypes', async () => {
    const { data } = await axios.get(`${url}/filters`);
    return data;
});

const filterTypeSlice = createSlice({
    name: 'filter',
    initialState: {
        filterTypes: [],
        status: {
            fetchFilterTypes: 'idle'
        },
        error: null,
    },
  
    extraReducers: {
        // filter types
        [fetchFilterTypes.pending]: (state, action) => {
            state.status.fetchFilterTypes = 'pending';
        },
        [fetchFilterTypes.fulfilled]: (state, action) => {
            state.filterTypes = action.payload;
            state.status.fetchFilterTypes = 'fulfilled';
        },
        [fetchFilterTypes.rejected]: (state, action) => {
            state.status.fetchFilterTypes = 'rejected';
        },
    }
});

export default filterTypeSlice.reducer;