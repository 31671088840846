import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'antd/dist/antd.css';

import './i18n';

import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store } from './redux/store';

import App from './App';
import { Auth } from './layouts';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Auth>
        <App />
      </Auth>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
