import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchTags = createAsyncThunk(
  'tags/fetchTags',
  async (filter) => {
    const { data } = await axios.post(`${url}/tag`, {
      filter,
    });
    return data;
  },
);

export const fetchDelete = (tagId) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/tag/one/${tagId}`);

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    filter: {},
    tags: [],
    status: {
      fetchTags: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchTags.pending]: (state) => {
      state.status.fetchTags = 'pending';
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.status.fetchTags = 'succeeded';
      state.tags = action.payload;
    },
    [fetchTags.rejected]: (state, action) => {
      state.status.fetchTags = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = tagSlice.actions;

export default tagSlice.reducer;
