import { configureStore } from '@reduxjs/toolkit';

import productSlice from './productSlice';
import filterSlice from './filterSlice';
import userSlice from './userSlice';
import newProductsSlice from './newProductsSlice';
import productTypesSlice from './productTypesSlice';
import tagSlice from './tagSlice';
import searchSlice from './searchSlice';
import vendorSlice from './vendorSlice';
import seriesSlice from './seriesSlice';
import colorSlice from './colorSlice';
import genderSlice from './genderSlice';
import couponSlice from './couponSlice';
import filterTypeSlice from './filterTypeSlice';
import reviewSlice from './reviewSlice';
import supplierAuthortiySlice from './supplierAuthoritySlice';
import stockOutSlice from './stockOutSlice';
import returnSlice from './returnSlice';

export const store = configureStore({
  reducer: {
    products: productSlice,
    filter: filterSlice,
    user: userSlice,
    newProducts: newProductsSlice,
    productTypes: productTypesSlice,
    tags: tagSlice,
    search: searchSlice,
    vendor: vendorSlice,
    series: seriesSlice,
    colors: colorSlice,
    gender: genderSlice,
    coupon: couponSlice,
    filterType: filterTypeSlice,
    review: reviewSlice,
    authority: supplierAuthortiySlice,
    stockout: stockOutSlice,
    return: returnSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

export default store;
