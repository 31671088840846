import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCoupon } from '../../redux/couponSlice';
import { fetchAllExchangeRates } from '../../redux/exchangeRatesSlice';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getVendorFilter } from '../../helpers';
import './Coupons.css';

import { Button, Select, Form } from 'antd';
import { Coupon, LocalSearch } from '../../components';

function Coupons() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const { vendor } = useSelector((state) => state.vendor);
  const { languagePath, user } = useSelector((state) => state.user);
  
  const { Option } = Select;
  const [form] = Form.useForm();

  const [coupons, setCoupons] = useState([]);
  const [filter, setFilter] = useState(() => {});
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if(!vendor?._id || !user) return;
    
    setFilter({ 
      ...filter, 
      vendor: { $in: getVendorFilter("all", vendor, user, "_id")?.vendor }
    });
  }, [vendor, user])

  useEffect(() => {
    if(!vendor?._id || !user || !filter?.vendor) return;

    fetchCoupon(filter)
      .then(res => {
          setCoupons(res)
      })
  }, [filter, vendor, user]);

  useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			  setCurrencies(res?.data);
		  })
	}, [])

  return (
    <div className="list-coupons-container">
      <div className="list-coupons-nav">
        <h2>{t('home.Coupons')}</h2>
        <div>
          <Button type="primary" onClick={() => {
            navigate(`${languagePath}/coupon/new`)
          }}>{t(`buttons.Add New Coupon`)}</Button>
        </div>
      </div>
      <div className="list-coupons-filter">
        <div className='search'>
          <LocalSearch items={coupons} type="coupon"/>
        </div>
        <Form
          form={form}
          className={`coupon-filter-form ${user?.additional_vendors?.length > 0 ? "three-grid" : ""}`}
          layout="inline"
        >
          { user?.additional_vendors?.length > 0 &&
            <Select
              onChange={(e) => {
                if(e === "all") {
                  setFilter({ 
                    ...filter, 
                    vendor: { $in: getVendorFilter("all", vendor, user, "_id")?.vendor }
                  });
                  return
                };
                setFilter({ 
                  ...filter, 
                  vendor: { $in: [e] }
                });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
              style={{ width: "150px" }}
              defaultValue="all"
            >
              <Option value="all">{t(`home.All Vendors`)}</Option>
              <Option value={vendor?._id}>{vendor?.name}</Option>
              {user?.additional_vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                <Option key={'vendor-select' + index} value={vendor._id}>
                  {vendor.name}
                </Option>
              ))}
            </Select> }
          <Select
            onChange={(e) => {
              form.setFieldsValue({
                type: "all"
              })
              if (e === 'all') {
                const newFilter = { ...filter };
                delete newFilter.status;
                setFilter(newFilter);
                return;
              }
              setFilter((state) => ({ status: e }));
            }}
            style={{ width:"150px" }}
            defaultValue="all"
          >
            <Option value="all">{t(`status.All Status`)}</Option>
            <Option value="Active">{t(`status.Active`)}</Option>
            <Option value="Passive">{t(`status.Passive`)}</Option>
            <Option value="Archived">{t(`status.Archived`)}</Option>
          </Select>
          <Select
            onChange={(e) => {
              form.setFieldsValue({
                status: "all"
              })
              if (e === 'all') {
                const newFilter = { ...filter };
                delete newFilter.discount_type;
                setFilter(newFilter);
                return;
              }
              setFilter((state) => ({ discount_type: e }));
            }}
            style={{ width:"150px" }}
            defaultValue="all"
          >
            <Option value="all">{t(`product_types.All Types`)}</Option>
            <Option value="Percentage">{t(`fields.coupon.Percentage`)}</Option>
            <Option value="Absolute">{t(`fields.coupon.Absolute`)}</Option>
          </Select>
        </Form>
      </div>
      <div className="list-coupons-table-head">
        <div className="list-coupons-table-check">*</div>
        <div className="list-coupons-table-coupon">{t(`fields.coupon.Coupon`)}</div>
        <div className="list-coupons-table-status">{t(`fields.product.Status`)}</div>
        <div className="list-coupons-table-type">{t(`fields.coupon.Type`)}</div>
        <div className="list-coupons-table-amount">{t(`fields.coupon.Amount`)}</div>
        <div className="list-coupons-table-start">{t(`fields.coupon.Start Date`)}</div>
        <div className="list-coupons-table-end">{t(`fields.coupon.End Date`)}</div>
      </div>
      {coupons.map((item) => (
        <Coupon key={item._id} item={item} currencies={currencies}/>
      ))}
    </div>
  );
}

export default Coupons;
