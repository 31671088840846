import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchNewProducts = createAsyncThunk(
  'new-products/fetchNewProducts',
  async (filter) => {
    const { data } = await axios.post(`${url}/new-products/filter`, filter);
    return data;
  },
);

export const fetchAllNewProducts = createAsyncThunk(
  'new-products/fetchAllNewProducts',
  async (filter) => {
    const { data } = await axios.post(`${url}/new-products/filter`, { filter: filter.filter });
    return data;
  },
);

export const fetchUpdateNewProducts = ({ id, product }) => axios.patch(`${url}/new-products/${id}`, { product });

export const fetchNewProductById = async (id) => {
  const { data } = await axios.get(`${url}/new-products/${id}`);
  return data;
};

export const fetchCreateNewProduct = (product, imageLinks, selectedNewImageForReq, chartImage, vendorLastProductCount) => axios.post(`${process.env.REACT_APP_BASE_ENDPOINT}/new-products/create`, {
  product, imageLinks, selectedImage: selectedNewImageForReq, chartImage, language : 'tr', vendorLastProductCount
});

export const fetchDeleteNewProduct = ({ id }) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/new-products/${id}`);

export const fetchUniqueVendors = async () => {
  const { data } = await axios.get(`${url}/new-products/vendors`);
  return data;
};

const newProductsSlice = createSlice({
  name: 'newProduct',
  initialState: {
    filter: {
      page: 1,
      limit: 100,
      filter: { $or: [{ blacklist: false }, { blacklist: { $exists: false } }] },
    },
    newProducts: [],
    productCount: 0,
    status: {
      fetchProducts: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      if (action.payload.sort) {
        if (typeof action.payload.sort === 'string') {
          state.filter.sort = JSON.parse(action.payload.sort);
        } else {
          state.filter.sort = action.payload.sort;
        }
      }
    },
  },
  extraReducers: {
    [fetchNewProducts.pending]: (state) => {
      state.status.fetchNewProducts = 'pending';
    },
    [fetchNewProducts.fulfilled]: (state, action) => {
      state.status.fetchNewProducts = 'succeeded';
      state.newProducts = action.payload.newProducts;
      state.productCount = action.payload.totalCount;
    },
    [fetchNewProducts.rejected]: (state, action) => {
      state.status.fetchNewProducts = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = newProductsSlice.actions;

export default newProductsSlice.reducer;
