import { t } from 'i18next';

const moment = require('moment');
const { fetchUploadUrl } = require('../redux/productSlice');

const loncaRound = (number, decimal) => {
  if (Number.isNaN(number)) return 0;
  if (Number.isNaN(decimal) || !decimal) decimal = 2;

  number = parseFloat(number);
  decimal = parseInt(decimal, 10);

  return Math.round(number * 10 ** decimal) / 10 ** decimal;
};

function getDate(date) {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) return 'NaN';
  return `${day}/${month}/${year}`;
}

function loncaFormatDate(date) {
  return moment(date).format('DD.MM.YYYY HH:mm');
}


const LOGIC_CHANGE_DATES = {
  MULTIPLIER_REMOVAL_DATE :  "2024-05-10T14:00:00",
}

const renderCalculatedVendorCogs = ({payment_at, cogs, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {
  const calculatedCogs = calculateVendorCogs({
    payment_at,
    cogs,
    rate_usd_tr_buying,
    rate_usd_buying,
    rate_usd_selling
  })
  return `₺ ${loncaRound(calculatedCogs)}`
}


const calculateLoncaVendorCurrency = ({payment_at, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {
  const shouldRemoveMultiplier = payment_at > LOGIC_CHANGE_DATES["MULTIPLIER_REMOVAL_DATE"]

  const lonceVendorCurrency = rate_usd_tr_buying
  const currencyMultiplier  = shouldRemoveMultiplier ? 1 : (rate_usd_buying / rate_usd_selling)
  return lonceVendorCurrency * currencyMultiplier
}

const renderLoncaVendorCurrency = (item) => {
  const loncaVendorCurrency = calculateLoncaVendorCurrency({
    payment_at: item?.payment_at, 
    rate_usd_tr_buying: item?.rate_usd_tr_buying,
    rate_usd_buying: item?.rate_usd_buying,
    rate_usd_selling: item?.rate_usd_selling
  })
  return `₺ ${loncaRound(loncaVendorCurrency)}`

}


const calculateVendorCogs = ({payment_at, cogs, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {

  const loncaVendorCurrency = calculateLoncaVendorCurrency({
    payment_at,
    rate_usd_tr_buying,
    rate_usd_buying,
    rate_usd_selling
  })

  const calculatedCogs = cogs * loncaVendorCurrency
  return calculatedCogs
}

function getChangedValues(product) {
  const changed = {
    names: product.names,
    description_details: product?.description_details,
    cogs: loncaRound(product.cogs),
    price: loncaRound(product.price),
    discounted_cogs: Number.isNaN(product.discounted_cogs) ? '0' : loncaRound(product.discounted_cogs),
    discounted_price: Number.isNaN(product.discounted_price) ? '0' : loncaRound(product.discounted_price),
    handle: product.handle,
    ranking: product.ranking,
    status: product.status,
    supplier_status: product.supplier_status,
    product_type: product.product_type,
    vendor: product.vendor,
    series: product.series,
    tags: product.tags,
    supplier_stock_code: product?.supplier_stock_code,
    supplier_link: product?.supplier_link,
    is_discounted: product.is_discounted,
    update_config: product?.update_config,
    gender: product.gender,
    color: product.color,
    change_log: product?.change_log,
  };

  if (product.stock || product.stock === 0) changed.stock = product.stock;
  if (!product.stock) changed.stock = 0;

  if (product.supplier_stock || product.supplier_stock === 0) {
    changed.supplier_stock = product.supplier_stock;
  }
  if (!product.supplier_stock) changed.supplier_stock = 0;

  if (product.in_transit_stock || product.in_transit_stock === 0) {
    changed.in_transit_stock = product.in_transit_stock;
  }
  if (!product.in_transit_stock) changed.in_transit_stock = 0;

  if (product.reserved_stock || product.reserved_stock === 0) {
    changed.reserved_stock = product.reserved_stock;
  }
  if (!product.reserved_stock) changed.reserved_stock = 0;

  if (product?.measurement) {
    changed.measurement = product.measurement;
  }

  if(product?.sub_series?.length > 0){
    changed.sub_series = product?.sub_series;
  }

  return changed;
}

function convertToHandle(name) {
  if (!name) return '';
  name = name.split(/[^0-9A-Za-z]+/).join(' ');
  name = name.trim();
  name = name.replaceAll(' ', '-').toLocaleLowerCase();
  return name;
}

const calculateItemPrice = ({ item, order = false }) => {
  const cogs = order ? item?.cogs : item?.product?.cogs;
  const vat = item?.product?.product_type?.vat;

  return loncaRound(cogs * (1 + vat));
};

const changeCartItem = (changeObject, setStockOutWarning) => {
  const { item, setItem, field, value, currentItem, series, isOrderStatus, initialItem, owner } = changeObject
  let newValue;
  let  statusLog;

  switch (field) {
    case 'quantity':
      newValue = value === '' ? 0 : parseInt(value, 10);
      break;

    case 'cogs':
      newValue = value === '' ? 0 : value;
      break;
    case 'series':
      newValue = value;
      break;
    case 'order_status':
      newValue = value;
      statusLog = {status: value, date: new Date(), source: 'supplier', owner};
      if(value === "StockOut"){ 
        setStockOutWarning(true);
      }
      break;
    case 'item_count':
      newValue = value;
      break;
    case 'notes':
      newValue = value;
      break;
    default:
      break;
  }

  if (isOrderStatus) {
    const newObj = { ...currentItem };
    newObj[field] = newValue;

    if (typeof statusLog !== 'undefined') {
      if (typeof newObj['status_log'] === 'undefined') {
        newObj['status_log'] = []; // Eğer 'status_log' henüz tanımlanmamışsa boş bir dizi oluşturur
      }

      const isItemStatusChanged = initialItem?.order_status != newObj?.order_status
    
    if (!isItemStatusChanged){
      newObj['status_log'] = initialItem?.status_log;
    }else{
      if(initialItem?.['status_log']?.length > 0) {
        newObj['status_log'] = [...initialItem['status_log'], statusLog];
      } else {
        newObj['status_log'] = [statusLog];
      }
    } 

    }

    setItem({
      ...item,
      cart_item: newObj,
    });

    return;
  }

  setItem({
    ...item,
    cart_item: item.cart_item.map((i) => {
      if (i._id === currentItem._id) {
        const newObj = { ...currentItem };
        newObj[field] = newValue;
        if (field === 'series') {
          newObj.item_count = series.find((it) => it.name === value).item_quantity;
        }
        return newObj;
      }
      return i;
    }),
  });
};

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);

  reader.onerror = (error) => reject(error);
});


const uploadMedias = async (vendor, fileList, sku, customIncrement = 0, type = "product", mediaType) => {
  const linkList = [];

  for (let index = 0; index < fileList.length; index += 1) {
    try {
      const replacedVendorName = type === "refund"
        ? vendor
        : vendor?.replaceAll(' ', '_');

      const response = await fetchUploadUrl(`${type}${mediaType === 'video' ? '/videos/raw' : ''}/${replacedVendorName}/${sku}-${index + 1 + customIncrement}`);
      await fetch(response.data, {
        method: 'PUT',
        body: fileList[index].originFileObj,
      });

      let imageUrl = response.data.split('?')[0]
      if(mediaType === 'video'){
        imageUrl = imageUrl?.replace('raw','compressed')
        imageUrl = imageUrl + '.mp4';
      }
      
      linkList.push(imageUrl);

      if (index === fileList.length - 1) return linkList;
    } catch (err) {
      console.error(err);
    }
  }

  return linkList;
};

const uploadChartImage = async (vendor, fileList, sku) => {
  try {
    const replacedVendorName = vendor?.replaceAll(' ', '_');
    const response = await fetchUploadUrl(`product/${replacedVendorName}/${sku}-${'size-chart-image'}`);
    await fetch(response.data, {
      method: 'PUT',
      body: fileList[0].originFileObj,
    });

    const imageUrl = response.data.split('?')[0];
    return imageUrl;
  } catch (err) {
    console.warn(err);
    return '';
  }
};

const defaultImage = 'https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-94-512.png';

const allOrderProductsAggregate = (vendor) => [
  {
    $project: { standard_shipment: 0, express_shipment: 0 },
  },
  {
    $match: {
      status: 'Received',
      cart_item: { $not: { $elemMatch: { product: { $in: [null] } } } },
    },
  },
  {
    $unwind: { path: '$cart_item', preserveNullAndEmptyArrays: true },
  },
  {
    $lookup: {
      from: 'parent_products',
      localField: 'cart_item.product',
      foreignField: '_id',
      as: 'cart_item.populated_product',
    },
  },
  {
    $unwind: { path: '$cart_item.populated_product', preserveNullAndEmptyArrays: true },
  },
  {
    $lookup: {
      from: 'scrapers',
      localField: 'cart_item.populated_product._id',
      foreignField: 'parent_product_id',
      as: 'scraper',
    },
  },
  {
    $unwind: { path: '$scraper', preserveNullAndEmptyArrays: true },
  },
  {
    $lookup: {
      from: 'product_types',
      localField: 'cart_item.populated_product.product_type',
      foreignField: '_id',
      as: 'cart_item.product_type',
    },
  },
  {
    $unwind: { path: '$cart_item.product_type', preserveNullAndEmptyArrays: true },
  },
  {
    $match: {
      'cart_item.populated_product.vendor': { $exists: true },
    },
  },
  {
    $match: {
      $expr: { $eq: ['$cart_item.populated_product.vendor', { $toObjectId: vendor?._id }] },
      'cart_item.order_status': { $in: ['Received', 'Confirmed', 'StockOut'] },
    },
  },
  {
    $lookup: {
      from: 'coupons',
      localField: 'coupon',
      foreignField: '_id',
      as: 'coupon',
    },
  },
  {
    $unwind: { path: '$coupon', preserveNullAndEmptyArrays: true },
  },
  {
    $sort: { payment_at: -1 },
  },
];

const getVendorFilter = (e, vendor, user, field, isNewProducts = false) => {
  const filterKey = isNewProducts ? "vendor" : "vendor._id";

  if (e === 'all') {
    let vendorFilter = { [filterKey]: { $in: [vendor[field]]} };

    if (user?.additional_vendors?.length > 0) {
      const vendorArray = [vendor[field]];
      user?.additional_vendors?.forEach((v) => vendorArray?.push(v[field]));
      vendorFilter = { [filterKey]: {$in: vendorArray} };
    }

    return vendorFilter;
  }
  const vendorFilter = { [filterKey]: {$in: [e]} };
  return vendorFilter;
};

const extractInvoiceItem = (item) => {
  const {
    unique_order_id: uniqueOrderId,
    cart_item: {
      _id,
      supplier_stock_code: populatedProductStockCode,
      product_name: populatedProductName,
      product_name_tr: populatedProductNameTr,
      names,
      product_image: populatedProductMainImage,
      quantity,
      item_count: itemCount,
    },
    payment_at: payment_at,
  } = item;

  return {
    _id,
    unique_order_id: uniqueOrderId,
    supplier_stock_code: populatedProductStockCode,
    name: populatedProductName,
    name_tr: populatedProductNameTr,
    names,
    payment_at,
    quantity,
    item_count: itemCount,
    main_image: populatedProductMainImage,
  };
};

const getTranslatedFieldName = (field, code) => {
  const sourceCode = code ==='gb' ? 'en' : code
  return field?.names?.[sourceCode]
}

const getTranslatedProductName = (product, code) => {
  const sourceCode = code ==='gb' ? 'en' : code
  return product?.names?.[sourceCode]
}

const separateAndCapitalize = (str, symbol = ' ') => {
  const words = str?.split(symbol);
  const capitalizedWords = words?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1));
  return capitalizedWords?.join(' ');
}

function isMediaListExceedSizeThreshold(fileList) {
  const MAX_MEDIA_THRESHOLD_MB = 100;

  const filesSizesMB = fileList?.map(file => file?.originFileObj?.size / (1024 * 1024))
  return filesSizesMB?.some((file) => file > MAX_MEDIA_THRESHOLD_MB)

}

const uploadVendorBannerImage = async (vendorName, fileList, fileType="banner") => {
  const linkList = [];

  for(let index = 0; index < fileList.length; index += 1){
    const fileUrl = `vendor/${vendorName.replace(" ", "_")}/${fileType.toLocaleLowerCase()}/${new Date().getTime()}.webp`
    await fetchUploadUrl(fileUrl, "image/webp")
        .then(async (response) => {
            await fetch(response.data, {
                method: "PUT",
                body: fileList[index].originFileObj,
            })

            const imageUrl = response.data.split('?')[0];
            linkList.push(imageUrl);
        })
        .catch((err) => {
            console.log(err);
            notification['error']({
                message: `Error uploading image #${index}`
            });
        });
  }
  return linkList;

}

const calculateVendorScore = (reviewsInfo) => {
    const totalUniqueReviews =  reviewsInfo?.products_reviews_count_test_group + reviewsInfo?.products_reviews_count_non_test_group;
    const totalTestScore =  reviewsInfo?.products_reviews_count_test_group * reviewsInfo?.products_reviews_score_test_group;
    const totalNonTestScore =  reviewsInfo?.products_reviews_count_non_test_group * reviewsInfo?.products_reviews_score_non_test_group;

    const totalScore = loncaRound(( totalTestScore + totalNonTestScore ) / totalUniqueReviews);
    
    return totalScore;
}

const calculateTotalReviewCount = (reviewsInfo) => {
  const totalUniqueReviews =  reviewsInfo?.products_reviews_total_count_test_group + reviewsInfo?.products_reviews_total_count_non_test_group;
  return totalUniqueReviews;
}

const getFlagUrl = (code) => {
  code = code?.toLowerCase();
  
  if(code === "ic") {
    return `https://flagcdn.com/28x21/es.png`;
  } else if(code === "kktc") {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Flag_of_the_Turkish_Republic_of_Northern_Cyprus.svg/320px-Flag_of_the_Turkish_Republic_of_Northern_Cyprus.svg.png";
  } else if(code === "yk") {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Flag_of_Kosovo.svg/1200px-Flag_of_Kosovo.svg.png";
  } else if (code === "el") {
    return "https://flagcdn.com/28x21/gr.png"
  } else {
    return `https://flagcdn.com/28x21/${code}.png`;
  }
}

const getAuthorityText = (authorityConfig, key) => {
    const config = authorityConfig?.find(c => c?.key === key);
    if(!config) return [];

    const {
      quantity_threshold,
      secondary_quantity_threshold,
      tertiary_quantity_threshold,
      punishment,
      secondary_punishment,
      tertiary_punishment
    } = config;

    let result = [];

    if (key === "review") {

      /* result.push(`${quantity_threshold.toFixed(2)}-${5.00} : 0 Puan alacaksınız.`); */
      result.push(t('supplier_authority.punishment_range_text', { first: quantity_threshold.toFixed(2), second: "5.00", punishment: "0" }))
      result.push(t('supplier_authority.punishment_range_text', { first: secondary_quantity_threshold.toFixed(2), second: (quantity_threshold - 0.01).toFixed(2), punishment: -punishment }))
      result.push(t('supplier_authority.punishment_range_text', { first: tertiary_quantity_threshold.toFixed(2), second: (secondary_quantity_threshold - 0.01).toFixed(2), punishment: -secondary_punishment }))
      result.push(t('supplier_authority.punishment_range_text', { first: "1.00", second: (tertiary_quantity_threshold - 0.01).toFixed(2), punishment: -tertiary_punishment }))
    } else {
      result.push(t('supplier_authority.punishment_range_with_percentage_text', { first: "0.00", second: quantity_threshold.toFixed(2), punishment: "0" }))
      result.push(t('supplier_authority.punishment_range_with_percentage_text', { first: (quantity_threshold + 0.01).toFixed(2), second: secondary_quantity_threshold.toFixed(2), punishment: -punishment }))
      result.push(t('supplier_authority.punishment_range_with_percentage_text', { first: (secondary_quantity_threshold + 0.01).toFixed(2), second: tertiary_quantity_threshold.toFixed(2), punishment: -secondary_punishment }))
      result.push(t('supplier_authority.punishment_range_with_percentage_text', { first: (tertiary_quantity_threshold + 0.01).toFixed(2), second: "100.00", punishment: -tertiary_punishment }))
    }

    return result;
}


export {
  loncaRound,
  loncaFormatDate,
  getDate,
  getChangedValues,
  defaultImage,
  convertToHandle,
  calculateItemPrice,
  changeCartItem,
  getBase64,
  uploadMedias,
  uploadChartImage,
  allOrderProductsAggregate,
  getVendorFilter,
  extractInvoiceItem,
  getTranslatedProductName,
  getTranslatedFieldName,
  separateAndCapitalize,
  calculateVendorCogs,
  renderCalculatedVendorCogs,
  renderLoncaVendorCurrency,
  isMediaListExceedSizeThreshold,
  uploadVendorBannerImage,
  calculateVendorScore,
  calculateTotalReviewCount,
  getFlagUrl,
  getAuthorityText
};
