import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, Button, Select } from 'antd';
import _ from 'underscore';

import ProductImporter from './ProductImporter';
import { exportProducts, fetchProducts, setFilter } from '../../redux/productSlice';
import { getVendorFilter } from '../../helpers';

import { Pagination, Product, SearchBar } from '../../components';

import './Products.css';


function Products() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const { filter, products, count } = useSelector((state) => state.products);
  const { tags } = useSelector((state) => state.tags);
  const { vendor } = useSelector((state) => state.vendor);
  const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
  const { languagePath, user } = useSelector((state) => state.user);
  const { selectedLanguage } = useSelector((state) => state.user);

  const selectedLanguageCode = selectedLanguage === "tr" ? "tr" : "en";

  const [exportLoading, setExportLoading] = useState(false);
  const [importerVisibility, setImporterVisibility] = useState(false);

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };

  useEffect(() => {
    if(!vendor?._id || !user) return;
    
    handleFilter({ 
      ...filter, 
      filter: { 
        ...filter.filter, 
        ...getVendorFilter("all", vendor, user, "_id")
      } 
    });
  }, [vendor, user])

  useEffect(() => {
    if(!vendor?._id || !user || !filter?.filter?.["vendor._id"]) return;

    const filterWithStatus = filter?.status ? filter : { ...filter, status: { $nin: ["Draft"] } };

    console.log("filterWithStatus", filterWithStatus)

    dispatch(fetchProducts(filterWithStatus));
  }, [filter, dispatch, vendor, user]);


  return (
    <div className="list-products-container">

      <ProductImporter 
        modalVisibility={importerVisibility}
        setModalVisibility={setImporterVisibility}
      />

      <div className="list-products-nav">
        <h2>{t('home.Products')}</h2>

        <div>
        <Button 
            type="text"
            loading={exportLoading}
            onClick={() => {
              setExportLoading(true);

              exportProducts(filter)
                .then(async (responseUrl) => {
                  setTimeout(async () => {
                    const response = await fetch(responseUrl?.data, {
                      headers: {
                        // Add any required headers here
                      },
                    });
                    if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
                    const blob = await response.blob();
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', "urunler.xlsx"); // Set the download filename
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
  
                    setExportLoading(false);
                  }, 1000)
                })
                .catch((err) => {
                  setExportLoading(false);
                })
            }}
          >
            { t("fields.product_import.export") }
          </Button>

          <Button type="text" onClick={() => setImporterVisibility(true)}>{t(`buttons.Import`)}</Button>

          <Button onClick={() => navigate(`${languagePath}/product/new`)} type="text">{t(`buttons.Add New Product`)}</Button>
        </div>
      </div>
      <div className='list-number-of-products'>
            Number of products: {count}
      </div>
      <div className="list-products-filter">
        <div className='search-p'>
          <SearchBar type="product"/>
        </div>
        <Form 
          form={form}
          layout="inline"
          initialValues={{
            vendor: filter.vendor ? filter.vendor : "all",
            product_type: filter.product_type ? filter.product_type : "all",
            tag: filter.tag ? filter.tag : "all",
            status: filter.status ? filter.status : "all",
            update_config: filter?.update_config || "all",
          }}
        >
          { user?.additional_vendors?.length > 0 && <Form.Item name="vendor">
            <Select
              onChange={(e) => {
                handleFilter({ 
                  ...filter, 
                  filter: { 
                    ...filter.filter, 
                    ...getVendorFilter(e, vendor, user, "_id")
                  } 
                });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              <Option value="all">{t(`home.All Vendors`)}</Option>
              <Option value={vendor?._id}>{vendor?.name}</Option>
              {user?.additional_vendors?.slice().sort((a, b) => a?.name?.localeCompare(b?.name))?.map((vendor, index) => (
                <Option key={'vendor-select' + index} value={vendor._id}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
          </Form.Item> }

          <Form.Item name="product_type">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  handleFilter({ ...filter, filter : (_.omit(filter.filter,'product_type'))});
                } else {
                  handleFilter({ ...filter, filter: { ...filter.filter, product_type: e } });
                }
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              <Option value="all">{t(`product_types.All Types`)}</Option>
              {productTypes?.slice().sort((a, b) => a?.name?.localeCompare(b?.name))?.map((type, index) => (
                <Option key={'vendor-select' + index} value={type._id}>
                  {t(`product_types.${type.name}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="tag">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  handleFilter({ ...filter, filter : (_.omit(filter.filter,'tags'))});
                } else {
                  handleFilter({ ...filter, filter: { ...filter.filter, tags: [e] } });
                }
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              <Option value="all">{t(`tags.All Tags`)}</Option>
              {tags?.slice().sort((a, b) => a?.name?.localeCompare(b?.name))?.map((tag, index) => (
                <Option key={'tag-select' + index} value={tag._id}>
                {`${t(`${tag?.names?.[selectedLanguageCode] || tag?.names?.en || tag?.name}`)}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="status">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  handleFilter({ ...filter, filter : (_.omit(filter.filter,'status'))});
                } else {
                  handleFilter({ ...filter, filter: { ...filter.filter, status: e } });
                }
              }}
            >
              <Option value="all">{t(`status.All Status`)}</Option>
              <Option value="Active">{t(`status.Active`)}</Option>
              <Option value="Passive">{t(`status.Passive`)}</Option>
              <Option value="Archived">{t(`status.Archived`)}</Option>
            </Select>
          </Form.Item>

          <Form.Item name="update_config">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  handleFilter({ ...filter, filter : (_.omit(filter.filter,'update_config'))});
                } else {
                  handleFilter({ ...filter, filter: { ...filter.filter, update_config: e } });
                }
              }}
              
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              <Option value="all">{t(`fields.product.All Update Configs`)}</Option>
              <Option value="price">{t(`fields.product.Price Fixed`)}</Option>
              <Option value="series">{t(`fields.product.Series Fixed`)}</Option>
              <Option value="status">{t(`fields.product.Status Fixed`)}</Option>
            </Select>
          </Form.Item>
          
          <Select
            onChange={(value) => {
              const newValue = JSON.parse(value);
              handleFilter({ ...filter, sort: newValue });
            }}
            defaultValue='{}'
            style={{ width: "130px" }}
          >
            <Option value='{}'>{t(`sort.Sort`)}</Option>
            <Option value='{"price": 1}'>
              {t(`sort.Price_Low to High`)}
            </Option>
            <Option value='{"price": -1}'>
              {t(`sort.Price_High to Low`)}
            </Option>
            <Option value='{ "name": 1}'>
              {t(`sort.Name_A to Z`)}
            </Option>
            <Option value='{ "name": -1}'>
              {t(`sort.Name_Z to A`)}
            </Option>
            <Option value='{"createdAt": -1 }'>
              {t(`sort.Date_New to Old`)}
            </Option>
            <Option value='{"createdAt": 1 }'>
              {t(`sort.Date_Old to New`)}
            </Option>
          </Select>
        </Form>
      </div>
      <div className="list-product-table-head">
        <div className="list-product-table-check">*</div>
        <div className="list-product-table-product">{t(`home.product`)}</div>
        <div className="list-product-table-status">{t(`home.status`)}</div>
        <div className="list-product-table-tag">{t(`home.tag`)}</div>
        <div className="list-product-table-type">{t(`home.type`)}</div>
        <div className="list-product-table-vendor">{t(`home.vendor`)}</div>
        <div className="list-product-table-price">{t(`home.price`)}</div>
        <div className="list-product-table-code">{t(`home.Stock Code`)}</div>
      </div>
      {products?.map((item) => (
        <Product key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default Products;
