import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchAllSeries = createAsyncThunk(
  'series/fetchAllSeries',
  async () => {
    const { data } = await axios.get(`${url}/series/all`);
    return data;
  },
);

export const fetchDelete = (id) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/series/one/${id}`);

export const fetchCreate = async (serie) => {
  const { data } = await axios.post(`${url}/series`, { serie });
  return data;
};

const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    filter: {},
    allSeries: [],
    status: {
      fetchAllSeries: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // get all series
    [fetchAllSeries.pending]: (state) => {
      state.status.fetchAllSeries = 'pending';
    },
    [fetchAllSeries.fulfilled]: (state, action) => {
      state.status.fetchAllSeries = 'succeeded';
      state.allSeries = action.payload;
    },
    [fetchAllSeries.rejected]: (state, action) => {
      state.status.fetchAllSeries = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = seriesSlice.actions;

export default seriesSlice.reducer;
