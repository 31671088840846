import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchLogin } from "../../redux/userSlice";

import "./Login.css";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, notification } from "antd";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Login = ({ route }) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { status, languagePath } = useSelector((state) => state.user);

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const onFinish = async (values) => {
    try {
      await dispatch(fetchLogin(values)).unwrap()
    } catch (error) {
      notification['error']({
        message: error.error,
      });
    }
  };

  useEffect(() => {
    if (status.auth === "succeeded") {
      navigate(query.get('path') ? `${languagePath}${query.get('path')}` : `${languagePath}/products`);
    };
  }, [navigate, status, query, languagePath]);

  return (
    <div className="container login-page">
      <h1>LONCA</h1>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t(`login.Please input your email!`),
            },
            {
              pattern:
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              message: "Invalid email",
            },
          ]}
        >
          <Input
            disabled={status.fetchLogin === "pending"}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t(`login.Email`)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t(`login.Please input your password!`),
            },
          ]}
        >
          <Input
            disabled={status.fetchLogin === "pending"}
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t(`login.Password`)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-primary">
            {t(`login.Log in`)}
          </Button>{" "}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
