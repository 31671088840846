import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../components";
import { Button, Collapse } from "antd";
import {
  SkinOutlined,
  MailOutlined,
  PlusOutlined,
  UserOutlined,
  GiftOutlined,
  ShoppingCartOutlined,
  GiftFilled,
  UsergroupAddOutlined,
  FileDoneOutlined,
  FieldTimeOutlined,
  FireOutlined,
  LineChartOutlined,
  SyncOutlined,
  PieChartOutlined,
  RollbackOutlined
} from "@ant-design/icons";

const { Panel } = Collapse;

import "./MainLayout.css";

import { fetchVendor } from "../../redux/vendorSlice";
import { fetchTags } from "../../redux/tagSlice";
import { fetchAllSeries } from "../../redux/seriesSlice";
import { fetchAllProductTypes } from "../../redux/productTypesSlice";
import { fetchColors } from "../../redux/colorSlice";
import { fetchGenders } from "../../redux/genderSlice";
import { fetchFilterTypes } from "../../redux/filterTypeSlice";
import { logOut } from "../../redux/userSlice";

import { LANGUAGES } from "../../constants";

function MainLayout() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const { status: userStatus, user, languagePath } = useSelector((state) => state.user);
  const { status: productsStatus } = useSelector((state) => state.products);
  const { status: vendorStatus, vendor } = useSelector((state) => state.vendor);
  const { status: tagStatus } = useSelector((state) => state.tags);
  const { status: seriesStatus } = useSelector((state) => state.series);
  const { status: productTypeStatus } = useSelector((state) => state.productTypes);
  const { status: colorStatus } = useSelector((state) => state.colors);
  const { status: genderStatus } = useSelector((state) => state.gender);
  const { status: filterTypeStatus } = useSelector((state) => state.filterType);

  const [ isStatisticsHidden, setIsStatisticsHidden ] = useState(true);

  useEffect(() => {
    if(userStatus.auth === "succeeded") {
      if (vendorStatus.fetchVendor === "idle") dispatch(fetchVendor({ _id: user.vendor }));
      if (colorStatus.fetchColors === "idle") dispatch(fetchColors());
      if (genderStatus.fetchGenders === "idle") dispatch(fetchGenders());
      if (tagStatus.fetchTags === "idle") dispatch(fetchTags());
      if (seriesStatus.fetchAllSeries === "idle") dispatch(fetchAllSeries());
      if (productTypeStatus.fetchAllProductTypes === "idle") dispatch(fetchAllProductTypes());
      if (filterTypeStatus.fetchFilterTypes === "idle") dispatch(fetchFilterTypes());
    }
  }, [dispatch, vendorStatus,productsStatus, user.vendor, userStatus.auth]);

  if (userStatus.auth !== "succeeded" && !localStorage.getItem("lonca-supplier-token")) {
    const currentLanguagePath = window.location.pathname.split("/")[1];
    const pathname = LANGUAGES.map(l => l.code).includes(currentLanguagePath) ? "/"+window.location.pathname.split("/").slice(2).join("/") : 
      window.location.pathname;

    return <Navigate to={`${languagePath}/login?path=${pathname}`} />
  };

  useEffect(() => {
    if(vendorStatus?.fetchVendor === "succeeded") {
      let isHidden = true;
      if(!vendor?.authority_validity_values) {
        isHidden = true
      } else {
        isHidden = Object?.values(vendor?.authority_validity_values || [])?.some((validity) => !validity);
      }
      setIsStatisticsHidden(isHidden);
    }
  }, [vendorStatus, vendor])
  
  return (
    <div className="layout">
      <div className="layout-nav">
        <div>LONCA</div>    
        <div className="buttons-item">
          <div><LanguageSelector /></div>
          <div className="logout"><Button onClick={() => dispatch(logOut())}>{t('home.Log Out')}</Button></div>
        </div>
      </div>
      <div className="container main-layout">
        <div className="layout-aside">
          <Link to={`${languagePath}/products`}>
            <Button icon={<SkinOutlined />}>{t('home.Products')}</Button>
          </Link>
          <Link to={`${languagePath}/new-products`}>
            <Button icon={<PlusOutlined />}>{t(`home.New Products`)}</Button>
          </Link>
          <Link to={`${languagePath}/order-products`}>
            <Button icon={<MailOutlined />}>{t(`home.Order Products`)}</Button>
          </Link>
          <Link to={`${languagePath}/late-products`}>
            <Button icon={<FieldTimeOutlined />}>{t(`home.Late Products`)}</Button>
          </Link>
          <Link to={`${languagePath}/all-orders`}>
            <Button icon={<ShoppingCartOutlined />}>{t(`home.All Orders`)}</Button>
          </Link>
          <Link to={`${languagePath}/coupons`}>
            <Button icon={<GiftOutlined />}>{t('home.Coupons')}</Button>
          </Link>
          <Link to={`${languagePath}/used-coupons`}>
            <Button icon={<GiftFilled />}>{t(`home.Used Coupons`)}</Button>
          </Link>
          <Link to={`${languagePath}/supplier`}>
            <Button icon={<UsergroupAddOutlined />}>{t(`home.Supplier Info`)}</Button>
          </Link>
          <Link to={`${languagePath}/user`}>
            <Button icon={<UserOutlined />}>{t(`home.User Info`)}</Button>
          </Link>
          <Link to={`${languagePath}/invoices`}>
            <Button icon={<FileDoneOutlined />}>{t(`home.Invoices`)}</Button>
          </Link>
          
          {!isStatisticsHidden && <Collapse className="custom-collapse">
            <Panel header={
                <span>
                  <PieChartOutlined style={{ marginRight: 8 }} />
                  {t('home.Statistics')}
                </span>
              }
              key="1"
            >
              <Link to={`${languagePath}/supplier-authority`}>
                <Button className="custom-collapse-button" icon={<LineChartOutlined />}>{t(`home.Supplier Authority`)}</Button>
              </Link>
              <Link to={`${languagePath}/returns`}>
                <Button className="custom-collapse-button" icon={<RollbackOutlined />}>{t(`home.Returns`)}</Button>
              </Link>
              <Link to={`${languagePath}/reviews`}>
                <Button className="custom-collapse-button" icon={<FireOutlined />}>{t(`home.Reviews`)}</Button>
              </Link>
              <Link to={`${languagePath}/stockouts`}>
                <Button className="custom-collapse-button" icon={<SyncOutlined />}>{t(`home.StockOuts`)}</Button>
              </Link>
            </Panel>
          </Collapse>}
        </div>
        <div className="layout-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
