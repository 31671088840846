import { useSelector } from "react-redux";
import { Button  } from "antd";
import { useTranslation } from "react-i18next";
import { loncaFormatDate } from "../../helpers";

import "./EditInvoiceItem.css";

const EditInvoiceItem = ({item, index,_id,unique_order_id,supplier_stock_code,name,name_tr,names,payment_at,order_addition_date, quantity,item_count,main_image,key,buttonFunctionality,buttonType}) => {
    const { t } = useTranslation("translation");
    const { selectedLanguage } = useSelector((state) => state.user);


    
return (
<div className="invoice-item-container" key={_id + index}>
  <div className="invoice-item-image-container">
    <img
      className="invoice-item-image"
      src={main_image}
      alt="product"
    ></img>
  </div>

  <div className="invoice-product-info">
    <div className="product-actions">
      <Button
        type="primary"
        danger={buttonType === "Remove"}
        onClick={() => buttonFunctionality(item)}
      >
        {t(`buttons.${buttonType}`)}
      </Button>
    </div>
    <div className="inline-info-three">
      <div className="invoice-item-info-item ">
        <span className="product-info-label">
          {t(`fields.product.unique_id`)}
        </span>
        <input
          className={`ant-input`}
          value={unique_order_id}
          disabled
        />
      </div>

      <div className="invoice-item-info-item">
        <span>
          {t(`fields.product.Stock Code`)}
          <input
            className={`ant-input`}
            placeholder="Stock Code"
            value={supplier_stock_code}
            disabled
          />
        </span>
      </div>
      <div className="invoice-item-info-item">
        <span>
          {t(`fields.product.Name Short`)}
          <input
            className={`ant-input`}
            placeholder="Name"
            value={selectedLanguage?.code === "tr" ? (names?.tr || name_tr) :  (names?.en || name)}
            disabled
          />
        </span>
      </div>
    </div>
    <div className="inline-info-three">
      <div className="invoice-item-info-item product-payment">
        <span className="product-info-label">
          {t(`fields.order_products.Quantity`)}
        </span>
        <input
          className="ant-input"
          placeholder="Quantity"
          value={quantity}
          disabled
        />
      </div>
      <div className="invoice-item-info-item product-payment">
        <span className="product-info-label">
          {t(`fields.order_products.Item Count`)}
        </span>
        <input
          className="ant-input"
          placeholder="Item Count"
          value={item_count}
          disabled
        />
      </div>
      <div className="invoice-item-info-item product-payment">
        <span>
          {t(`fields.order_products.Payment At`)}
          <input
            className={`ant-input`}
            placeholder="Order Date"
            value={order_addition_date ? loncaFormatDate(order_addition_date) : loncaFormatDate(payment_at)}
            disabled
          />
        </span>
      </div>
    </div>
  </div>
</div>)
}


export default EditInvoiceItem;
