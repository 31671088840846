import { t } from "i18next";
import React, { useEffect, useState } from "react";
import "./Returns.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllReasons, fetchAuthorityConfigs, fetchReturns, fetchReturnsByReason } from "../../redux/returnSlice";
import { Pagination, Select, Spin, Table, Tooltip } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import Return from "./components/Return";

const Returns = () => {
    const dispatch = useDispatch();
    const { vendor, status: { fetchVendor: vendorStatus } } = useSelector((state) => state.vendor);
    const { returns, authorityConfigs, allReturns: allVendorReturns, reasonCounts, allReasons, status: { fetchReturnsByReason: returnsByReasonsStatus, fetchReturns: returnsStatus}  } = useSelector((state) => state.return);
    const [ vendorReturns, setVendorReturns ] = useState({});
    const [ returnConfig, setReturnConfig ] = useState({});
    const [ returnCountsByReason, setReturnCountsByReason ] = useState({});
    const [ allReturns, setAllReturns ] = useState([]);
    const [ allReturnsInitial, setAllReturnsInitial ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ loadingReasons, setLoadingReasons ] = useState(true);
    const [ selectedRegion, setSelectedRegion ] = useState("all");
    const [ allReturnReasons, setAllReturnReasons ] = useState([]);
    const [ selectedReasons, setSelectedReasons ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pageNum, setPageNum ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ isHidden, setIsHidden ] = useState(true);
    
    const REGIONS = [
        { label: t('returns.All Countries'), value: "all" },
        { label: t('returns.Big Countries'), value: "big" },
        { label: t('returns.Small Countries'), value: "small" },
    ]

    useEffect (() => {
        dispatch(fetchReturns());
        dispatch(fetchAuthorityConfigs());
        dispatch(fetchAllReasons());
    }, []);

    useEffect(() => {
        if(vendor?._id) {
            let isHidden = true;
            if(!vendor?.authority_validity_values) {
                isHidden = true
            } else {
                isHidden = Object?.values(vendor?.authority_validity_values || [])?.some((validity) => !validity);
            }
            setIsHidden(isHidden)
            setVendorReturns({
                bigCountries: {
                    return: vendor?.authority_details?.refund_big_amount,
                    total: vendor?.authority_details?.refund_big_total_amount,
                    returnQuantityValue: vendor?.authority_details?.refund_big
                },
                smallCountries: {
                    return: vendor?.authority_details?.refund_small_amount,
                    total: vendor?.authority_details?.refund_small_total_amount,
                    returnQuantityValue: vendor?.authority_details?.refund_small
                }
            });
            setLoadingReasons(true);
            dispatch(fetchReturnsByReason({vendorId: vendor?._id, selectedRegion: selectedRegion}));
        }
    }, [vendor?._id, selectedRegion]);

    useEffect(() => {
        if(authorityConfigs?.length > 0) {
            const config = authorityConfigs?.find(config => config?.key === "refund");
            setReturnConfig(config);
        }
    }, [authorityConfigs]);

    useEffect(() => {
        if(returnsStatus === "fulfilled" && vendorStatus === "succeeded") {
            setLoading(false);
        }
    }, [returnsStatus, vendorStatus]);

    useEffect(() => {
        if(returnsByReasonsStatus === "fulfilled") {
            setReturnCountsByReason(reasonCounts);
            if(selectedReasons?.length === 0) {
                setAllReturns(allVendorReturns);
            } else {
                setAllReturns(
                    allVendorReturns?.filter(r => selectedReasons?.includes(r?.refund_reason))
                );
            }
            setAllReturnsInitial(allVendorReturns);
            setLoadingReasons(false);

            const totalReturnCount = Object?.values(returnCountsByReason).reduce((acc, count) => acc + count, 0);
            setTotalCount(totalReturnCount);
        }
    }, [returnsByReasonsStatus, reasonCounts]);

    useEffect(() => {
        if(allReasons?.length > 0) {
            const validReasons = allReasons?.filter(r => r?.name !== "Missing Pack");
            setAllReturnReasons(
                validReasons?.map(reason => {
                    return {
                        value: reason?.name,
                        label: t(`returns.${reason.name}`)
                    }
                })
            )
        }
    }, [allReasons]);

    const formatReturnValues = (returns) => {
        return [
            {
                key: 'Highest Score',
                metric: 'Highest Score',
                bigCountries: returns?.bigCountries?.maxReturnValue?.returnQuantityValue,
                smallCountries: returns?.smallCountries?.maxReturnValue?.returnQuantityValue,
            },
            {
                key: 'Lowest Score',
                metric: 'Lowest Score',
                bigCountries: returns?.bigCountries?.minReturnValue?.returnQuantityValue,
                smallCountries: returns?.smallCountries?.minReturnValue?.returnQuantityValue
            },
            {
                key: 'Mean Score',
                metric: 'Mean Score',
                bigCountries: returns?.bigCountries?.meanReturnValue?.returnQuantityValue,
                smallCountries: returns?.smallCountries?.meanReturnValue?.returnQuantityValue
            }
        ];
    };

    /* const formatVendorReturnValues = (returns) => {
        return [
            { key: '1', metric: 'Count', bigCountries: returns?.bigCountries?.return, smallCountries: returns?.smallCountries?.return },
            { key: '2', metric: 'Total', bigCountries: returns?.bigCountries?.total, smallCountries: returns?.smallCountries?.total },
            { key: '3', metric: 'Proportion', bigCountries: returns?.bigCountries?.returnQuantityValue, smallCountries: returns?.smallCountries?.returnQuantityValue }
        ];
    } */

    const formatReturnCountsByReason = () => {
        const totalReturnCount = Object?.values(returnCountsByReason).reduce((acc, count) => acc + count, 0);

        const returns = Object.keys(returnCountsByReason).map((reason) => ({
            key: reason,
            returnReason: reason,
            count: returnCountsByReason[reason],
            proportion: ((returnCountsByReason[reason] / totalReturnCount) * 100).toFixed(2) + '%',
        }));
        
        if(returns?.length > 0) {
            let proportion;

            if(selectedRegion === "all") {
                const totalCount = vendorReturns?.bigCountries?.total + vendorReturns?.smallCountries?.total;
                const returnCount = vendorReturns?.bigCountries?.return + vendorReturns?.smallCountries?.return;
                proportion = ((returnCount / totalCount) * 100).toFixed(2) + '%' 
            } else {
                proportion = selectedRegion === "big" ? `${vendorReturns?.bigCountries?.returnQuantityValue}%` : `${vendorReturns?.smallCountries?.returnQuantityValue}%`
            }

            returns.unshift({
                key: "All",
                returnReason: "all",
                count: totalReturnCount,
                proportion: proportion
            });
        }

        return returns;
    };
    

    const RETURN_TOP_VENDORS_COLUMNS = [
        {
            title: '',
            dataIndex: 'metric',
            key: 'metric',
            render: (text) => (
                <span>{t(`returns.${text}`)}</span>
            ),
        },
        {
            title: (
                <span>
                    {t('returns.Big Countries')}
                    <Tooltip title={t('returns.Big Countries Description')}>
                        <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'bigCountries',
            key: 'bigCountries',
            render: (text) => loading ? <Spin /> : `${text}%`
        },
        {
            title: (
                <span>
                    {t('returns.Small Countries')}
                    <Tooltip title={t('returns.Small Countries Description')}>
                        <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'smallCountries',
            key: 'smallCountries',
            render: (text) => loading ? <Spin /> : `${text}%`
        }
    ];

    const RETURN_REASON_COLUMNS = [
        {
            title: (
                <span>
                    {t('returns.Return Reason')}
                    <Tooltip title={t("returns.Return Reason Description")} />
                </span>
            ),
            dataIndex: 'returnReason',
            key: 'returnReason',
            render: (text) => {
                if(text === "all") {
                    return (
                        <span>
                            {t(`returns.${text}`)}
                        </span>
                    )
                } else {
                    return (
                        <span>
                            {t(`returns.${text}`)}
                            <Tooltip title={t(`returns.${text} Description`)}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }}  />
                            </Tooltip>
                        </span>
                    )
                }
            }
        },
        {
            title: (
                <span>
                    {t('returns.Count')}
                    <Tooltip title={t("returns.Count Description")} />
                </span>
            ),
            dataIndex: 'count',
            key: 'count',
            render: (text) => text
        },
        {
            title: (
                <span>
                    {t('returns.Proportion')}
                    <Tooltip title={t("returns.Proportion Description")} />
                </span>
            ),
            dataIndex: 'proportion',
            key: 'proportion',
            render: (text) => text
        }
    ];

    const onPageChange = (e) => {
        window.scrollTo({top: 1000, behavior: "smooth"});
        setPageNum(e);
    }

    const onSelectedReasonsChange = (checkedValues) => {
        setPageNum(1);
        setSelectedReasons(checkedValues);
        if(checkedValues?.length === 0) {
            setAllReturns(allReturnsInitial);
        } else {
            setAllReturns(
                allReturnsInitial?.filter(r => checkedValues?.includes(r?.refund_reason))
            );
        }
    }

    const onRegionChange = (e) => {
        setPageNum(1);
        setSelectedRegion(e);
    }

    if(isHidden) {
        return null;
    }

    return (
        <div>
            <h2 className="returns-header">{t('home.Returns')}</h2>

            <div className="returns">
                <Table
                    title={() => (
                        <span>{
                            t('returns.Top Vendors')}
                            <Tooltip title={t('returns.Top Vendors Description')}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="returns-table"
                    columns={RETURN_TOP_VENDORS_COLUMNS}
                    dataSource={formatReturnValues(returns)}
                    rowKey="name"
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                />
            </div>

            <div className="return-filters">
                <Select
                    options={REGIONS}
                    value={selectedRegion}
                    showSearch
                    onChange={onRegionChange}
                    style={{width: "200px", marginTop: "20px"}}
                    showArrow
                    filterOption={(input, option) =>  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                />

                <Select
                    mode="multiple"
                    options={allReturnReasons}
                    placeholder={t('returns.Return Reasons')}
                    onChange={onSelectedReasonsChange}
                    showSearch
                    maxTagCount="responsive"
                    style={{width: "200px", marginTop: "20px"}}
                    showArrow
                    allowClear
                    filterOption={(input, option) =>  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                />
            </div>

            <div className="returns">
                <Table
                    title={() => (
                        <span>
                            {t('returns.My Score')}
                            <Tooltip title={t('returns.My Score Description', { value: returnConfig?.last_months })}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="returns-table"
                    columns={RETURN_REASON_COLUMNS}
                    dataSource={formatReturnCountsByReason()}
                    rowKey="returnReason"
                    pagination={false}
                    scroll={{ y: 700 }}
                    bordered
                    loading={loadingReasons}    
                    rowClassName={(record, index) => {
                        return index === 0 ? 'first-row' : '';
                    }}
                />
            </div>
            
            <div className="all-returns">
                <h3 className="return-products-header">{t('returns.Return Products')}</h3>

                {!loadingReasons ?
                    allReturns?.slice((pageNum-1)*pageSize, pageNum*pageSize)?.map((currentReturn) => {
                        return (
                            <Return singleReturn={currentReturn}/>
                        )
                    })
                :
                    <Spin />
                }
            </div>

            {!loadingReasons && <div>
                <Pagination current={pageNum} total={allReturns?.length} onChange={onPageChange} showSizeChanger={false}/>
            </div>}
        </div>
    )
}

export default Returns;