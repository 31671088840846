import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchOrderAggregate = ({ filter, mode = "", cancelToken = null }) => {
  const requestBody = {
    filter,
    mode,
  };

  return axios.post(`${url}/order/aggregate-order-for-supplier`, requestBody, {
    cancelToken: cancelToken,
  });
};

export const fetchCalculateAndUpdate = async (changedItems, beforeUpdateOrders = null, forceUpdate = false, conflictCheckOnly = true) => {
  const { data } = await axios.post(`${url}/order/calculate-update`, { changedItems, beforeUpdateOrders, forceUpdate, conflictCheckOnly });
  return data;
};

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orders: [],
    status: {},
    error: null,
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  }
});

export const { setOrders } = orderSlice.actions;
export default orderSlice.reducer;
