const DESI_CONSTANT = 5000;

const SUPPLIER_VALID_ORDER_PRODUCTS_ORDER_STATUSES = ['Received', 'Customer Pending', 'Confirmed', "Operations Pending", "Ready"]; 
const SUPPLIER_VALID_ORDER_PRODUCTS_ITEM_STATUSES = ['Received', 'Confirmed', 'StockOut', 'Wrong / Missing Items'];
const SUPPLIER_VALID_LATE_PRODUCTS_ITEM_STATUSES = ['Received', 'Confirmed', 'Wrong / Missing Items'];

const SUPPLIER_VALID_ORDER_PRODUCTS_EDIT_ITEM_STATUSES = ['Received', 'Confirmed', 'StockOut'];
const SUPPLIER_DISABLE_EDIT_ORDER_STATUSES =  ["Customer Pending", "Confirmed", "Operations Pending", "Ready", "On the Road", "Delivery", "Completed"]
const SUPPLIER_DISABLE_ORDER_PRODUCTS_STATUSES =  ["Wrong / Missing Items", "StockOut", "Confirmed"]

const LANGUAGE_ROUTES = ['/gb', '/'];
const LANGUAGES = [
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'gb',
    name: 'English',
  },
];

const REVIEW_QUESTIONS = [
  { id: "quality", question: "quality" },
  { id: "expectation", question: "expectation" },
  { id: "arrive", question: "arrive" }
];

const EU_BIG_COUNTRIES = ["Poland", "Germany", "France", "Spain", "Italy"];

module.exports = {
  SUPPLIER_VALID_ORDER_PRODUCTS_ORDER_STATUSES,
  SUPPLIER_VALID_ORDER_PRODUCTS_ITEM_STATUSES,
  SUPPLIER_DISABLE_EDIT_ORDER_STATUSES,
  DESI_CONSTANT,
  LANGUAGE_ROUTES,
  LANGUAGES,
  SUPPLIER_VALID_ORDER_PRODUCTS_EDIT_ITEM_STATUSES,
  SUPPLIER_VALID_LATE_PRODUCTS_ITEM_STATUSES,
  SUPPLIER_DISABLE_ORDER_PRODUCTS_STATUSES,
  REVIEW_QUESTIONS,
  EU_BIG_COUNTRIES,
};
