import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from 'socket.io-client';
import { useTranslation } from "react-i18next";

import { notification } from "antd";
import { 
    CloseOutlined, 
    WarningOutlined, 
    ExclamationCircleOutlined, 
    CheckCircleOutlined, 
    LoadingOutlined,
    PlusOutlined,
    FileDoneOutlined
} from '@ant-design/icons';

import { importProducts, importProductsTemplate } from "../../redux/productSlice";

import "./ProductImporter.scss";

const ProductImporter = ({ modalVisibility, setModalVisibility }) => {

    const { t } = useTranslation();

    const { user } = useSelector((state) => state.user);

    const [selectedFile, setSelectedFile] = useState();
    const [immidiateError, setImmidiateError] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [showFinishedMessage, setShowFinishedMessage] = useState("");
    const [finishedMessageComponent, setFinishedMessageComponent] = useState("");
    const [importLoading, setImportLoading] = useState(false);
    const [tab, setTab] = useState("update");
    const [templateDownloadLoading, setTemplateDownloadLoading] = useState(false);
    const [socketRefresh, setSocketRefresh] = useState(false);

    const udpateMandatoryFields = ["Lonca Stok Kodu"];
    const udpateAllowedFields = ["Tedarikçi Stok Kodu", "Ürün Statüsü", "Renk", "Seri", "Ürün Tipi", "Fiyat Kuru", "Türkçe Ürün İsmi", "Fiyat", "İndirimli Fiyat", "Stok Adedi", "Tedarikçi Ürün Linki", "Kumaş", "Model Ölçüleri", "Örnek Beden", "Ürün Ölçüleri", "Beden Tablosu Linki", "Görsel 1", "Görsel 2", "Görsel 3", "Görsel 4", "Görsel 5", "Görsel 6"];

    const entryMandatoryFields = ["Ürün Statüsü", "Renk", "Seri", "Ürün Tipi", "Fiyat Kuru", "Türkçe Ürün İsmi", "Fiyat", "Stok Adedi", "Kumaş", "Model Ölçüleri", "Örnek Beden", "Ürün Ölçüleri", "Görsel 1"];
    const entryAllowedFields = ["Tedarikçi Stok Kodu", "İndirimli Fiyat", "Tedarikçi Ürün Linki", "Beden Tablosu Linki", "Görsel 2", "Görsel 3", "Görsel 4", "Görsel 5", "Görsel 6"];

    const refreshState = () => {
        setImmidiateError("");
        setShowSuccessMessage(false);
        setShowFinishedMessage(false);
        setImportLoading(false);
    }

    useEffect(() => {
        refreshState();
    }, [selectedFile]);

    useEffect(() => {
        // Connect to Socket.IO server
        let socketUrl = process.env.REACT_APP_BASE_ENDPOINT;
        socketUrl = socketUrl?.slice(0, socketUrl?.length - 7);
        const socket = io(socketUrl);

        socket.emit('register', user?._id);
    
        // Listen for import update messages
        socket.on('productImportUpdate', (message) => {
            setImmidiateError("");
            notification['success']({
                message: t("fields.product_import.ongoing_title"),
                description: (
                    <div>
                        <div>{ t("fields.product_import.processed_line_count") }: {message?.processedRowCount} / {message?.totalRowCount}</div>
                        <div>{ t("fields.product_import.detected_error_count") }: {message?.errorCount}</div>
                    </div>
                )
            });
        });

        socket.on('productImportError', (message) => {
            notification['error']({
                message: t("fields.product_import.error_title"),
                description: (
                    <div>{ t(`fields.product_import.${message}`) }</div>
                )
            });
        });

        socket.on('productImportMailError', () => {
            notification['error']({
                message: t("fields.product_import.error_title"),
                description: (
                    <div>{ t(`fields.product_import.mail_error_description`) }</div>
                )
            });
        });

        socket.on('productImportFinished', (message) => {
            notification['success']({
                message: t("fields.product_import.completed"),
                description: (
                    <div>
                        <div>{ t("fields.product_import.processed_line_count") }: {message?.processedRowCount} / {message?.totalRowCount}</div>
                        <div>{ t("fields.product_import.updated_product_count") }: {message?.updateCount} / {message?.totalRowCount}</div>
                        <div>{ t("fields.product_import.detected_error_count") }: {message?.errorCount}</div>
                        { parseInt(message?.errorCount) > 0 && <div>{ t("fields.product_import.check_your_email") }</div> }
                    </div>
                ),
                duration: 7
            });

            setImmidiateError("");
            setShowFinishedMessage(true);
            setFinishedMessageComponent((
                <div>
                    <div>{ t("fields.product_import.processed_line_count") }: {message?.processedRowCount} / {message?.totalRowCount}</div>
                    <div>{ t("fields.product_import.updated_product_count") }: {message?.updateCount} / {message?.totalRowCount}</div>
                    <div>{ t("fields.product_import.detected_error_count") }: {message?.errorCount}</div>
                    { parseInt(message?.errorCount) > 0 && <div>{ t("fields.product_import.check_your_email") }</div> }
                </div>
            ))
        });
    
        return () => {
            socket.disconnect();
        };
    }, [socketRefresh]);

    if (!modalVisibility) return null;

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Check if file is larger than 5MB
        if (file && file.size > 5 * 1024 * 1024) {
            notification["error"]({
                message: t("fields.product_import.file_size_error")
            });
            // Optionally, you can clear the selected file input if the file is too large
            event.target.value = null; // This resets the file input
        } else {
            setSelectedFile(file);
        }
    };

    const handleUpload = async () => {
        refreshState();

        if (!selectedFile) {
            notification["error"]({
                message: t("fields.product_import.select_a_file_error"),
            });
            return;
        }

        if (importLoading) return;
        setImportLoading(true);
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            const base64String = reader.result;
            const base64Content = base64String.split(';base64,')[1];
            // Send the CSV content to the backend
            importProducts({ data: base64Content, isEntry: tab === "entry" })
                .then(() => {
                    setShowSuccessMessage(true);
                    setSocketRefresh(r => !r);
                    setImportLoading(false);
                    setImmidiateError("");
                })
                .catch(err => {
                    setImmidiateError(err?.response?.data?.error);
                    setImportLoading(false);

                    notification['error']({
                        message: t("fields.product_import.error_title"),
                        description: (
                            <div>{ getImmidiateErrorText(err?.response?.data?.error) }</div>
                        )
                    });
                })
        };
        reader.readAsDataURL(selectedFile);
    };

    const closeImporter = () => {
        setImmidiateError("");
        setModalVisibility(false);
        setShowSuccessMessage(false);
        setImportLoading(false);
        setSelectedFile(null);
    }

    const getImmidiateErrorText = (errorLabel) => {
       return  t(`fields.product_import.${errorLabel}`);
    }

    return (
        <div className="product-importer-container">
            <div className="product-importer-header">
                <div className="product-importer-header-tabs">
                    <h2 
                        className={`product-importer-header-title ${tab === "update" ? "product-importer-active-header-title" : ""}`}
                        onClick={() => setTab("update")}
                    >
                        {t(`fields.product_import.update_tab_title`)}
                    </h2>
                    <h2 
                        className={`product-importer-header-title ${tab === "entry" ? "product-importer-active-header-title" : ""}`}
                        onClick={() => setTab("entry")}
                    >
                        {t(`fields.product_import.entry_tab_title`)}
                    </h2>
                </div>
                <span 
                    className="product-importer-header-close"
                    onClick={closeImporter}
                >
                    <CloseOutlined />
                </span>
            </div>

            <div className="product-importer-description">
                <p className="product-importer-description-text">
                    {t(`fields.product_import.product_import_${tab}_decription`)}
                </p>

                <div className="import-template-button-container">
                    <button 
                        className="import-template-button"
                        onClick={() => {
                            setTemplateDownloadLoading(true);

                            importProductsTemplate({ isEntry: tab === "entry" })
                                .then(async responseUrl => {
                                    setTimeout(async () => {
                                        const response = await fetch(responseUrl?.data);
                                        if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
                                        const blob = await response.blob();
                                        const downloadUrl = window.URL.createObjectURL(blob);
                                        const link = document.createElement('a');
                                        link.href = downloadUrl;

                                        const fileName = tab === "entry" ? "yeni_urun_ekleme_sablonu.xlsx" : "urun_guncelleme_sablonu.xlsx";

                                        link.setAttribute('download', fileName); // Set the download filename
                                        document.body.appendChild(link);
                                        link.click();
                                        link.parentNode.removeChild(link);

                                        setTemplateDownloadLoading(false)
                                    }, 1000)
                                })
                                .catch(() => setTemplateDownloadLoading(false))
                        }}
                    >
                        {templateDownloadLoading && <LoadingOutlined />} {" "} {t(`fields.product_import.download_${tab}_template`)}
                    </button>
                </div>

                <div className="cautions-title">{t("fields.product_import.cautions")}</div>

                <ul className="">
                    <li>{t(`fields.product_import.${tab}_caution_1`)}</li>
                    <li>{t(`fields.product_import.${tab}_caution_2`)}</li>
                </ul>

                <div className="product-importer-allowed-fields-container">

                    <div className="product-importer-allowed-fields-title">{t("fields.product_import.mandatory_fields")}</div>

                    <div className="product-importer-allowed-fields">
                        {
                            (tab === "entry" ? entryMandatoryFields : udpateMandatoryFields).map(field => (
                                <span className="product-importer-allowed-field">{ field }</span>
                            ))
                        }
                    </div>

                    <div className="product-importer-allowed-fields-title">{t("fields.product_import.allowed_fields")}</div>

                    <div className="product-importer-allowed-fields">
                        {
                            (tab === "entry" ? entryAllowedFields: udpateAllowedFields).map(field => (
                                <span className="product-importer-allowed-field">{ field }</span>
                            ))
                        }
                    </div>

                    <div className="product-importer-allowed-fields-warning">
                        <div className="product-importer-allowed-fields-warning-title">
                            <WarningOutlined /> {t("fields.product_import.warning")}
                        </div>
                        <p>{t("fields.product_import.warning_description")}</p>
                    </div>
                </div>
            </div>

            <div className="product-importer-file-input">
                <input type="file" id="file-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange}/>

                <label for="file-input" className="product-importer-file-input-button"><PlusOutlined /> {t("fields.product_import.upload_file")}</label> {" "}

                { selectedFile && 
                    <span>
                        <FileDoneOutlined /> 
                        { selectedFile?.name } {" "} 

                        <CloseOutlined 
                            className="product-importer-file-input-delete-button"
                            onClick={() => {
                                document.getElementById('file-input').value = "";
                                setSelectedFile(null);
                            }}
                        />
                    </span> 
                }
            </div>

            { immidiateError &&
                <div className="product-importer-immidiate-error">
                    <div className="product-importer-immidiate-error-title">
                        <ExclamationCircleOutlined /> {t("fields.product_import.error")}
                    </div>
                    <p>{ getImmidiateErrorText(immidiateError) }</p>
                </div>
            }

            { (showSuccessMessage && !showFinishedMessage) &&
                <div className="product-importer-success-message">
                    <div className="product-importer-success-message-title">
                        <CheckCircleOutlined /> {t("fields.product_import.started_to_progress")}
                    </div>
                    <p>{t("fields.product_import.started_update")}</p>
                </div>
            }

            { showFinishedMessage &&
                <div className="product-importer-success-message">
                    <div className="product-importer-success-message-title">
                        <CheckCircleOutlined /> { t("fields.product_import.completed") }
                    </div>
                    { finishedMessageComponent }
                </div>
            }

            <div className="product-importer-buttons">
                <button 
                    className="product-importer-button product-importer-submit-button"
                    onClick={handleUpload}
                >
                    { importLoading && <LoadingOutlined /> } {" "} {t("fields.product_import.import")}
                </button>

                <button
                    className="product-importer-button product-importer-cancel-button"
                    onClick={closeImporter}
                >
                    {t("fields.product_import.cancel")}
                </button>
            </div>
        </div>
    );
};

export default ProductImporter;