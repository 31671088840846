import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchGenders = createAsyncThunk(
  'gender/fetchGenders',
  async () => {
    const { data } = await axios.get(`${url}/gender`);
    return data;
  },
);

const GenderSlice = createSlice({
  name: 'gender',
  initialState: {
    filter: {},
    genders: [],
    status: {
      fetchGenders: 'idle',
    },
    error: null,
  },
  reducers: {

  },
  extraReducers: {
    [fetchGenders.pending]: (state) => {
      state.status.fetchGenders = 'pending';
    },
    [fetchGenders.fulfilled]: (state, action) => {
      state.status.fetchGenders = 'succeeded';
      state.genders = action.payload;
    },
    [fetchGenders.rejected]: (state, action) => {
      state.status.fetchGenders = 'rejected';
      state.error = action.payload;
    },
  },
});

export default GenderSlice.reducer;
