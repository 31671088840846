import { Rate } from "antd";
import React, { useEffect, useState } from "react";
import { getFlagUrl } from "../../../helpers";
import moment from "moment";
import { REVIEW_QUESTIONS } from "../../../constants";
import { t } from "i18next";
import { LikeOutlined, DislikeOutlined, MessageOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Review = ({review}) => {
    const { selectedLanguage, languagePath } = useSelector((s) => s.user);
    const [selectedLanguageCode, setSelectedLanguageCode] = useState("tr");
    const navigate = useNavigate();
    const [original, setOriginal] = useState(false);
    
    const handleProductNavigation = (handle) => {
        navigate(`../${languagePath}/product/${handle}`);
    }

    const anonymizeName = (fullName) => {
        
        const [firstName, lastName] = fullName.split(' ');
          
        const anonymizedFirstName = firstName.slice(0, 2) + '*'.repeat(firstName.length - 2);
        const anonymizedLastName = lastName.slice(0, 2) + '*'.repeat(lastName.length - 2);
    
        return `${anonymizedFirstName} ${anonymizedLastName}`;
      }

    useEffect(() => {
        const newCode = selectedLanguage?.code === "gb" ? "en" : selectedLanguage?.code;
        setSelectedLanguageCode(newCode)
    }, [selectedLanguage])

    return (
        <div className="single-review-container">
            <div className="review-name-and-date">
                <img 
                    className="review-customer-country-image"
                    height='12' 
                    width='16' 
                    alt={review?.country_name} 
                    src={getFlagUrl(review?.country_code)}
                />
                <span>{anonymizeName(review?.customer_full_name)}</span>
                <div className="review-customer-name-and-date-separator"></div>
                <span>{moment(review?.createdAt).format("DD.MM.YYYY HH:mm")}</span>
            </div>

            <Rate 
                className="review-star"
                style={{fontSize: "12px"}}
                value={review?.rating}
                disabled
            />

            {
                REVIEW_QUESTIONS.map(({ id, question }) => (
                    <div>
                        <span>{t(`review_product.${question}`)}</span>
                            <span className={`${review?.answers?.[id] ? "review-question-yes" : "review-question-no"}`}>
                                {review?.answers?.[id] ? 
                                        <span><LikeOutlined />  {t(`review_product.yes`)}</span>
                                    :
                                        <span><DislikeOutlined />  {t(`review_product.no`)}</span>
                                }
                            </span>
                    </div>
                    
                ))
            }

            <div>
                <span onClick={() => handleProductNavigation(review?.product_handle)} className="review-related-product">{t('review_product.related_product')} {review?.product_names[`${selectedLanguage?.code}`]}</span>

                <div className="review-product-images-container">
                    {review?.product_images?.length > 0 &&
                        review?.product_images?.slice(0, 3)?.map((image) => (
                            <div className="review-product-img-wrapper">
                                <img
                                    src={image}
                                    alt="product"
                                    className="review-product-img" 
                                    onClick={() => handleProductNavigation(review?.product_handle)}
                                />
                                
                            </div>
                    ))}
                </div>
            </div>

            {review?.review && <div className="review-container">
                <MessageOutlined className="message-icon" />
                <span>
                    {!original ? review?.review_translations?.[selectedLanguageCode]: review?.review}
                </span>
                <a className="see-original-text" onClick={() => setOriginal(!original)}>
                    {!original ? t('review_product.see_original') : t('review_product.see_translate')}
                </a>
            </div>}
        </div>
    )
}

export default Review;