import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchCoupon = async (filter) => {
  const { data } = await axios.get(`${url}/coupon`, { params: filter });
  return data;
};

export const fetchCreate = (coupon) => axios.post(`${url}/coupon/new`, { coupon });

export const fetchCouponByCode = (code) => axios.get(`${url}/coupon/one/${code}`);

export const fetchDelete = (id) => axios.delete(`${url}/coupon/one/${id}`);

export const fetchUpdate = (id, update) => axios.patch(`${url}/coupon/one/${id}`, { update });

export const fetchUpdateStatus = (id, status) => axios.patch(`${url}/coupon/status/${id}`, { update: { status } });

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    coupons: [],
    activeCoupons: [],
    status: {
      fetchCoupon: 'idle',
    },
    error: null,
  },
  reducers: {},

});

export default couponSlice.reducer;
