import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchVendor = createAsyncThunk(
  'products/fetchVendor',
  async (filter) => {
    const { data } = await axios.post(`${url}/vendor`, {
      filter,
    });

    return data;
  },
);

export const fetchVendorIndex = (filter) => axios.post(`${url}/vendor`, {
  filter,
});

export const fetchUpdateStatus = (id, status) => axios.patch(`${process.env.REACT_APP_BASE_ENDPOINT}/vendor/one/${id}`, {
  vendorUpdates: {
    status,
  },
  productsUpdates: {
    status,
  },
});

export const fetchUpdate = (id, update) => axios.patch(`${process.env.REACT_APP_BASE_ENDPOINT}/vendor/basic-update/${id}`, {
  update,
});

export const fetchDelete = (id) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/vendor/one/${id}`);

export const fetchVendorUser = async (vendorId) => {
  const { data } = await axios.post(`${url}/vendor/user`, { vendor: vendorId });
  return data;
};

const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    filter: {},
    vendor: {},
    status: {
      fetchVendor: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchVendor.pending]: (state) => {
      state.status.fetchVendor = 'pending';
    },
    [fetchVendor.fulfilled]: (state, action) => {
      state.status.fetchVendor = 'succeeded';
      [state.vendor] = action.payload;
    },
    [fetchVendor.rejected]: (state, action) => {
      state.status.fetchVendor = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter, setVendors } = vendorSlice.actions;

export default vendorSlice.reducer;
