import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchColors = createAsyncThunk(
  'colors/fetchColors',
  async () => {
    const { data } = await axios.get(`${url}/color`);
    return data;
  },
);

export const fetchDelete = (tagId) => axios.delete(`${process.env.REACT_APP_BASE_ENDPOINT}/color/${tagId}`);

const colorSlice = createSlice({
  name: 'color',
  initialState: {
    colors: [],
    status: {
      fetchColors: 'idle',
    },
    error: null,
  },
  reducers: {

  },
  extraReducers: {
    [fetchColors.pending]: (state) => {
      state.status.fetchColors = 'pending';
    },
    [fetchColors.fulfilled]: (state, action) => {
      state.status.fetchColors = 'succeeded';
      state.colors = action.payload;
    },
    [fetchColors.rejected]: (state, action) => {
      state.status.fetchColors = 'rejected';
      state.error = action.payload;
    },
  },
});

// Delete This Line
export const { setFilter } = colorSlice.actions;

export default colorSlice.reducer;
