import React, { useEffect, useState } from "react";
import './SupplierAuthority.scss'
import { Table, Tooltip } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthorityConfig, fetchTopScores } from "../../redux/supplierAuthoritySlice.js";
import { useNavigate } from "react-router-dom";
import { getAuthorityText } from "../../helpers/index.js";

const SupplierAuthority = () => {
    const { vendor } = useSelector((state) => state.vendor);
    const { topScores, authorityConfig } = useSelector((state) => state.authority);
    const [ authorityValues, setAuthorityValues ] = useState({});
    const [ isHidden, setIsHidden ] = useState(true);
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    
    useEffect(() => {
        dispatch(fetchTopScores())
        dispatch(fetchAuthorityConfig());
    }, []);

    useEffect(() => {
        if(vendor?._id) {
            setAuthorityValues(vendor?.authority_values);
            let isHidden = true;
            if(!vendor?.authority_validity_values) {
                isHidden = true
            } else {
                isHidden = Object?.values(vendor?.authority_validity_values || [])?.some((validity) => !validity);
            }
            setIsHidden(isHidden)
        }
    }, [vendor?._id]);

    const validRowsForDescription = ["review", "refund", "stockout"]


    const SUPPLIER_AUTHORITY_COLUMNS = [
        {
          title: '',
          dataIndex: 'metric',
          key: 'metric',
          fixed: 'left',
          render: (text) => {
            const info = getAuthorityText(authorityConfig, text);

            return (
                
                <Tooltip title={
                    <>
                        {t(`supplier_authority.${text} description`)}
                        <br /><br />
                        {validRowsForDescription.includes(text) && <span style={{fontWeight: "600"}}>{t(`supplier_authority.${text}_ranges`)}</span>}
                        {info?.map(i => (
                            <div>{i}</div>
                        ))} 
                    </>
                }>
                    {<span>{t(`supplier_authority.${text}`)}</span>}
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                </Tooltip> 
                
            )
          },
        },
        {
        title: (
            <span>
                {t("supplier_authority.Big Countries")}
                <Tooltip title={t("supplier_authority.Big Countries Description")}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                </Tooltip>
            </span>
        ),
          dataIndex: 'big', 
          key: 'big',
        },
        {
        title: (
            <span>
                {t("supplier_authority.Small Countries")}
                <Tooltip title={t("supplier_authority.Small Countries Description")}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                </Tooltip>
            </span>
        ), 
          dataIndex: 'small',
          key: 'small',
        },
    ];
      
    const formatAuthorityValues = (authorityValues) => {
        return [
          { key: '1', metric: 'review', big: authorityValues?.review_big, small: authorityValues?.review_small },
          { key: '2', metric: 'refund', big: authorityValues?.refund_big, small: authorityValues?.refund_small },
          { key: '3', metric: 'stockout', big: authorityValues?.stockout_big, small: authorityValues?.stockout_small },
          { key: '4', metric: 'operation', big: authorityValues?.relationship, small: authorityValues?.relationship },
          { key: '5', metric: "supplier authority value", big: vendor?.ranking_authority_test_group, small: vendor?.ranking_authority}
        ];
    };

    const handleRowClick = (record) => {
        if(record?.metric === "review") {
            navigate("/reviews");
        } else if(record?.metric === "stockout") {
            navigate("/stockouts");
        } else if(record.metric === "refund") {
            navigate("/returns");
        }
    }
      
    const SUPPLIER_AUTHORITY_TOP_VENDORS_COLUMNS = [
        {
            title: '',
            dataIndex: 'metric',
            key: 'metric',
            fixed: 'left',
            render: (text) => (
                <Tooltip title={t(`supplier_authority.${text} Description`)}>
                  <span>{t(`supplier_authority.${text}`)}</span>
                  <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                </Tooltip>
              ),
        },
        {
            title: (
                <span>
                  {t('supplier_authority.Big Countries')}
                  <Tooltip title={t('supplier_authority.Big Countries Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'big',
            key: 'big',
        },
        {
            title: (
                <span>
                  {t('supplier_authority.Small Countries')}
                  <Tooltip title={t('supplier_authority.Small Countries Description')}>
                    <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                  </Tooltip>
                </span>
            ),
            dataIndex: 'small',
            key: 'small',
        },
    ];

    const formatTopScores = (topScores) => {
        if (!topScores || topScores.length === 0) {
            return [];
        }
    
        return [
            {
                key: '1',
                metric: "Highest Score",
                big: topScores?.[0]?.big_highest || 'N/A',
                small: topScores?.[0]?.small_highest || 'N/A'
            },
            {
                key: '2',
                metric: "Lowest Score",
                big: topScores?.[0]?.big_lowest || 'N/A',
                small: topScores?.[0]?.small_lowest || 'N/A',
            },
        ];
    };

    if(isHidden) {
        return null;
    }
    
    
    return (
        
        <div>
            <h2 className="supplier-authority-header">{t('home.Supplier Authority')}</h2>

            {topScores?.length > 0 && <div className="supplier-authority-top-vendors">
                <Table
                    title={() => (
                        <span>{
                            t('supplier_authority.Top Vendors')}
                            <Tooltip title={t('supplier_authority.Top Vendors Description')}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="supplier-authority-top-vendors-table"
                    columns={SUPPLIER_AUTHORITY_TOP_VENDORS_COLUMNS}
                    rowKey="name"
                    dataSource={formatTopScores(topScores)}
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                />
            </div>}

            <div className="supplier-authority-top-vendors">
                <Table
                    title={() => (
                        <span>{
                            t('supplier_authority.My Score')}
                            <Tooltip title={t('supplier_authority.My Score Description')}>
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: "green" }} />
                            </Tooltip>
                        </span>
                    )}
                    className="supplier-authority-top-vendors-table"
                    columns={SUPPLIER_AUTHORITY_COLUMNS}
                    dataSource={formatAuthorityValues(authorityValues)}
                    rowKey="name"
                    pagination={false}
                    scroll={{ y: 300 }}
                    bordered
                    rowClassName={(record, index) => {
                        const totalRows = formatAuthorityValues(authorityValues).length;
                        return index === totalRows - 1 ? 'last-row' : '';
                    }}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record)
                    })}
                />
            </div>
        </div>
    )
}

export default SupplierAuthority;