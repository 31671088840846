import React from 'react';
import './FileViewerUploader.css'
import { useTranslation } from "react-i18next";

const FileViewerUploader = ({fileList, onFileListChange}) => {

    const { t } = useTranslation('translation');
   
    const handleRemoveFile = (file) => {
    const updatedFileList = fileList.filter((f) => f !== file);
    onFileListChange(updatedFileList);
    };


    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const updatedFileList = selectedFiles.map((file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          return {
            file,
            preview: URL.createObjectURL(file),
          };
        });
        onFileListChange([...fileList, ...updatedFileList]);
      };

  return (
    <div>
      <input type="file" multiple onChange={handleFileChange} accept="image/*,.pdf" />
      <div className="file-preview-container">
        {fileList?.map((file, index) => (
          <div key={index} className="file-preview-item">
            {(file?.type ? file?.type.includes('pdf') : file?.file?.type.includes('pdf')) ? (
              <embed src={file?.preview} width="300" height="300" />
            ) : (
              <img src={file?.preview} width="250" height="250" alt={file?.file?.name} className="file-preview-image" />
            )}
            <button className="remove-button" onClick={() => handleRemoveFile(file)}> {t(`buttons.Remove`)}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileViewerUploader;
