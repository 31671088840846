import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import lodash from "lodash";

import { getTranslatedProductName } from '../../helpers';

import {
  fetchSearchBarResults,
  searchBarItemsSelector,
  searchTypeSelector,
  setSearchText,
  setSearchType
} from '../../redux/searchSlice';

import './SearchBar.css';

function SearchBar({ type }) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchBarItems = useSelector(searchBarItemsSelector);
  const searchType = useSelector(searchTypeSelector);
  const { vendor } = useSelector((state) => state.vendor);
  const { languagePath, selectedLanguage } = useSelector((state) => state.user);

  const [localSearchText, setLocalSearchText] = useState('');
  const [display, setDisplay] = useState(false);

  const debouncedFetchSearch = useCallback(
    lodash.debounce((localSearchText) => {
      dispatch(fetchSearchBarResults({ searchText: localSearchText, searchType, supplier: vendor?._id }));
    }, 300), 
    [dispatch, searchType, vendor]
  );

  useEffect(() => {
    dispatch(setSearchType(type));
  }, [dispatch, type])

  useEffect(() => {
    if (localSearchText?.length > 0) {
      debouncedFetchSearch(localSearchText);
    }
  }, [localSearchText]);

  return (
    <div className="search-wrapper">
      <div className="search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDisplay(false);
            dispatch(setSearchText(localSearchText));
            setLocalSearchText('');
          }}
        >
          <div className="search-area">
            <i className="bi bi-search search-icon"></i>
            <input
              type="text"
              placeholder={t(`placeholders.Search`)}
              value={localSearchText}
              onChange={(e) => {
                setLocalSearchText(e.target.value);
                e.target.value.length > 0
                  ? setDisplay(true)
                  : setDisplay(false);
              }}
            />
          </div>
          {display && searchBarItems.length > 0 && (
            <div className="search-results">
              <ul className="search-result">
                {searchBarItems?.map((result) => (
                  <li
                    key={result._id}
                    onClick={() => {
                      setDisplay(false);
                      setLocalSearchText('');
                      if(result.type === "product") navigate(`${languagePath}/product/${result.handle}`)
                    }}
                  >
                    <div className='search-result-box'>
                      <span className="name">{getTranslatedProductName(result, selectedLanguage?.code)}</span>
                      <span className="name">{result?.supplier_stock_code}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
