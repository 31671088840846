import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { setFilter, fetchNewProducts } from '../../redux/newProductsSlice';
import { fetchExchangeRates } from '../../redux/exchangeRatesSlice';
import { getVendorFilter } from '../../helpers';
import { Pagination, NewProduct } from '../../components';
import { Form, Select } from 'antd';

import './NewProducts.css';

function NewProducts() {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();

    const { filter, newProducts, productCount } = useSelector((state) => state.newProducts);
    const { vendor } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.user);

    const [form] = Form.useForm();
    const [TL_USD_SELLING, setTL_USD_SELLING] = useState(0);
    const [readyToFetch, setReadyToFetch] = useState(false);
    const handleFilter = (payload) => {
        dispatch(setFilter(payload));
    };

    useEffect(() => {
        if(!vendor?._id || !user) return;

        handleFilter({ 
          ...filter, 
          filter: { 
            ...filter.filter, 
            ...getVendorFilter("all", vendor, user, "name", true)
          } 
        });
        setReadyToFetch(true)
      }, [vendor, user])

    useEffect(() => {
        if(vendor?._id && user && readyToFetch) {
            dispatch(fetchNewProducts(filter));
        }
    }, [filter, dispatch, vendor, user, readyToFetch]);

    useEffect(() => {
        fetchExchangeRates({ abbreviation: "TRY" })
          .then(res => {
            setTL_USD_SELLING(res?.data?.rate_usd_selling)
          }) 
    }, [])

    const { Option } = Select;

    return (
        <div>
            <h2 className='title'>{t(`home.New Products`)}</h2>
            <div className="list-products-filter" >
                <div className='number-of-products'>
                    {t(`placeholders.Number of products`)}: {productCount}
                </div>

                <Form
                    form={form}
                    layout="inline"
                    initialValues={{
                        product: filter.product ? filter.product : "{}",
                        code: filter.code ? filter.code : "{}",
                        vendor: filter.vendor ? filter.vendor : "all",
                        status: filter.status ? filter.status : "all",
                    }}
                >
                    { user?.additional_vendors?.length > 0 && <Form.Item name="vendor">
                        <Select
                            onChange={(e) => {
                                handleFilter({ 
                                    ...filter, 
                                    filter: { 
                                        ...filter.filter, 
                                        ...getVendorFilter(e, vendor, user, "name", true)
                                    } 
                                });
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                            }
                        >
                            <Option value="all">{t(`home.All Vendors`)}</Option>
                            <Option value={vendor?.name}>{vendor?.name}</Option>
                            {user?.additional_vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                                <Option key={'vendor-select' + index} value={vendor.name}>
                                    {vendor.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> }

                    <Form.Item name="product">
                        <Select
                            onChange={(e) => {
                                handleFilter({ ...filter, sort: e });
                            }}
                        >
                            <Option value="{}">{t(`sort.Product Name_All`)}</Option>
                            <Option value='{"name": 1}'>{t(`sort.Product Name_A to Z`)}</Option>
                            <Option value='{"name": -1}'>{t(`sort.Product Name_Z to A`)}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="code">
                        <Select
                            onChange={(e) => {
                                handleFilter({ ...filter, sort: e });
                            }}
                        >
                            <Option value="{}">{t(`sort.Code_All`)}</Option>
                            <Option value='{"stock_code": 1}'>{t(`sort.Code_A to Z`)}</Option>
                            <Option value='{"stock_code": -1}'>{t(`sort.Code_Z to A`)}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="status">
                        <Select
                            onChange={(e) => {
                                if (e === 'all') {
                                    handleFilter({ ...filter, filter: { ...filter.filter, status: { $exists: true } } });
                                } else {
                                    handleFilter({ ...filter, filter: { ...filter.filter, status: e } });
                                }
                            }}
                        >
                            <Option value="all">{t(`status.All Status`)}</Option>
                            <Option value="Active">{t(`status.Active`)}</Option>
                            <Option value="Problematic">{t(`status.Problematic`)}</Option>
                            <Option value="Passive">{t(`status.Passive`)}</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div className="list-new-products-table-head">
                <div className="list-new-products-table-check">*</div>
                <div className="list-new-products-table-product">{t(`home.product`)}</div>
                <div className="list-new-products-table-status">{t(`home.status`)}</div>
                <div className="list-new-products-table-code">{t(`home.code`)}</div>
                <div className="list-new-products-table-vendor">{t(`home.vendor`)}</div>
                <div className="list-new-products-table-price">{t(`home.price`)}</div>
            </div>
            {newProducts?.map((item) => <NewProduct key={item?._id} item={item} TL_USD_SELLING={TL_USD_SELLING}/>)}
            <div>
                <Pagination filter={filter} setFilter={handleFilter} />
            </div>
        </div>
    );

}

export default NewProducts;
