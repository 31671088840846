import axios from 'axios';

const url = `${process.env.REACT_APP_BASE_ENDPOINT}/supplier-invoice`;

export const fetchSendCartItemsToInvoice = async (uniqueId, cartItems, images) => axios.post(`${url}/add-items`, {
  uniqueId,
  cartItems,
  images,
});

export const fetchSupplierInvoices = (filter) => axios.post(`${url}/filter`, { query: filter });

export const fetchCreateSupplierInvoice = (invoiceItem, vendor) => axios.post(`${url}/`, { invoice_item: invoiceItem, vendor });

export const fetchSupplierInvoiceItemsbyVendor = (vendorId, additionalVendors) => axios.post(`${url}/cart-items`, { vendorId, additionalVendors });

export const fetchRecordedItemsbyVendor = (vendorId) => axios.post(`${url}/recorded-items`, { vendorId });

export const fetchSupplierInvoiceByUniqueId = (uniqueId) => axios.get(`${url}/one/${uniqueId}`);

export const fetchDelete = (id) => axios.delete(`${url}/one/${id}`);

export const fetchUpdate = (id, update) => axios.patch(`${url}/one/${id}`, { update });
