import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchStockOuts = createAsyncThunk('stockouts/fetchStockOuts', async () => {
    const result = await axios.get(`${url}/supplier-authority/stockout-values`);

    return result?.data;
});

export const fetchAuthorityConfigs = createAsyncThunk('stockouts/fetchAuthorityConfigs', async () => {
    const result = await axios.get(`${url}/supplier-authority/get-configs`);

    return result?.data;
});

const stockOutSlice = createSlice({
    name: 'stockout',
    initialState: {
        stockOuts: {},
        authorityConfigs: [],
        status: {
            fetchStockOuts: 'idle',
            fetchAuthorityConfigs: 'idle'
        },
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchStockOuts.pending]: (state) => {
            state.status.fetchStockOuts = 'pending';
        },
        [fetchStockOuts.fulfilled]: (state, action) => {
            state.status.fetchStockOuts = 'fulfilled';
            state.stockOuts = action.payload;
        },
        [fetchStockOuts.rejected]: (state, action) => {
            state.status.fetchStockOuts = 'rejected';
            state.error = action.error.message;
        },

        [fetchAuthorityConfigs.pending]: (state) => {
            state.status.fetchAuthorityConfigs = 'pending';
        },
        [fetchAuthorityConfigs.fulfilled]: (state, action) => {
            state.status.fetchAuthorityConfigs = 'fulfilled';
            state.authorityConfigs = action.payload;
        },
        [fetchAuthorityConfigs.rejected]: (state, action) => {
            state.status.fetchAuthorityConfigs = 'rejected';
            state.error = action.error.message;
        }
    },
});

export default stockOutSlice.reducer;