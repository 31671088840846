import React, { useState } from 'react';
import { Button, Upload, Modal, notification } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

export const VideoUploader = ({
  fileList,
  product,
  handlePreview,
  setFileList,
  getBase64,
  previewVisible,
  previewTitle,
  previewType,
  previewImage,
  setPreviewVisible,
  isEditable = true,
  nonEditableTitle
}) => {

    return (
        <div className="">
            {isEditable ?
                <div style={{"position": "relative"}}>
                    <p>Add Videos for Product</p>
                </div> :
                <p>{nonEditableTitle}</p>
            }
            
            <div style={{marginTop: "10px"}}>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    itemRender={(originNode, file, fileList, { remove }) => {
                    return (
                        <div className={`single-image`}>
                        {file?.existBeforeUpdate ?
                            <video width={86} height={86} src={file.url} alt={file.uid} />
                            : <video width={86} height={86} src={file.url} alt={file.uid} />
                        }
                        <div className="shadow-img" onClick={(e) => {handlePreview(file)}}>
                            {isEditable &&
                                <DeleteOutlined className="trash-selector" 
                                    onClick={() => {
                                        remove();
                                    }} 
                                />
                            }
                        </div>
                        </div>
                    )
                    }}
                    onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                    if (fileList.length < newFileList.length) {
                        const url = await getBase64(updatedFile.originFileObj)
                        setFileList([...newFileList.slice(0, newFileList.length - 1), { ...newFileList[newFileList.length - 1], status: "done", url}]);
                    } else if (fileList.length > newFileList.length) {
                        let updatedList = newFileList.filter(f => f.status !== "removed");
                        setFileList(updatedList);
                    }
                    }}
                >
                    {isEditable && <div>
                        <PlusOutlined />
                        <div
                            className="plus-outlined-margin"
                        >
                            Upload
                        </div>
                    </div>}
                </Upload>
            </div>
            

            <Modal visible={previewVisible && previewType === 'video'} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                <video
                controls
                autoPlay={true}
                className="input-width"
                src={previewImage}
                />
            </Modal>

        </div>
    );
};