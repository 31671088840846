import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import './Coupon.css';

import { Button, Select, notification } from 'antd';
import { fetchDelete, fetchUpdateStatus } from '../../redux/couponSlice';
import { DeleteOutlined } from '@ant-design/icons';

import { getDate } from '../../helpers';

function Coupon({ item, currencies }) {
  const { t } = useTranslation('translation');

  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);
  const { languagePath } = useSelector((state) => state.user);

  const { Option } = Select;
  const navigate = useNavigate();

  const currency = currencies.find(c => c?.abbreviation === item?.currency) || currencies.find(c => c?.abbreviation === "USD")

  const handleUpdate = (id, status) => {
    fetchUpdateStatus(id, status)
      .then(() => {
        notification['success']({
          message: t(`errors.Modify successful`),
        });
      })
      .catch((err) => {
        notification['error']({
          message: err?.response?.data?.message,
        });
      });
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    fetchDelete(item._id)
      .then(() => {
        notification['success']({
          message: t(`errors.Deleting successful`),
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };
  if (!visible) return null;
  return (
    <div className="list-coupon">
      <div 
        className="list-coupon-item"
        onClick={() => {
            navigate(`${languagePath}/coupon/${item?._id}`)
        }}
      >
        <div className="list-coupon-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-coupon-item-coupon">{item.code}</div>
        <div className="list-coupon-item-status" onClick={(e) => e.stopPropagation()}>
          <Select
            defaultValue={item.status}
            onChange={(e) => handleUpdate(item._id, e)}
          >
            <Option value="Active">{t(`status.Active`)}</Option>
            <Option value="Passive">{t(`status.Passive`)}</Option>
            <Option value="Archived">{t(`status.Archived`)}</Option>
          </Select>
        </div>
        <div className="list-coupon-item-type">{t(`fields.coupon.${item.discount_type}`)}</div>
        <div className="list-coupon-item-amount">{item.discount_type === "Absolute" ? `${item.discount_amount}${currency?.symbol}` : `${item.discount_amount*100}%`}</div>
        <div className="list-coupon-item-start">{!(item.start_date) ? "-" : getDate(item.start_date)}</div>
        <div className="list-coupon-item-end">{!(item.end_date) ? "-" : getDate(item.end_date)}</div>
        <div 
          id="trash" 
          className={!selected ? 'visibility-hidden' : null}
          onClick={(e) => e.stopPropagation()}
        >
          <Button onClick={(e) => handleDelete(e)} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default Coupon;
