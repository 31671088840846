import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchOrderAggregate } from '../../redux/orderSlice';
import { loncaFormatDate } from "../../helpers";
import "./UsedCoupons.css";

const UsedCoupons = () => {
    const { t } = useTranslation('translation');

    const { vendor } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.user);

    const [TL_USD_BUYING, setTL_USD_BUYING] = useState();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(-1);
    const [dateFilter, setDatefFilter] = useState({ start: null, end: null });
    const [selectedVendors, setSelectedVendors] = useState([]);

    const { Option } = Select;

    useEffect(() => {
        if(vendor?._id && user) {
            setSelectedVendors([
                vendor?.name,
                ...(user?.additional_vendors?.map(v => (v?.name)) || [])
            ]);
        }
    }, [vendor, user]);

    useEffect(() => {
        fetchExchangeRates({ abbreviation: "TRY" })
            .then(res => {
                setTL_USD_BUYING(res?.data?.rate_usd_buying)
            })
    }, []);

    useEffect(() => {
        setLoading(true);
        if(vendor?._id) fetchOrderAggregate({
                filter: [
                    dateFilter, selectedVendors, sort
                ],
                mode: "used_coupons"
            })
            .then((res) => {
                setOrders(res.data);
                setLoading(false);
            })
            .catch((err) => console.log(err));

    }, [vendor, dateFilter, sort, selectedVendors]);

    return (
        <div>
            <div className='top-bar'>
                <h2>{t(`home.Used Coupons`)}</h2>
            </div>

            <div className='used-coupon-form-label-bar'>
                <div className='form-bar-select used-filter-container'></div>
                <div className='form-bar-select used-filter-container'></div>
                <div className='form-bar-select used-filter-container'>
                    {user?.additional_vendors?.length > 0 ? t(`home.Vendor`) : null}
                </div>
                <div className='form-bar-select used-filter-container'>
                    {t(`sort.Sort`)}
                </div>
            </div>  

            <div className="all-form-bar">
                <div className='form-bar-select filter-container'></div>
                <div className='form-bar-select filter-container'></div>
                { user?.additional_vendors?.length > 0 ?
                    <Select
                        onChange={(e) => {
                            if(e === "all") {
                                setSelectedVendors([
                                    vendor?.name,
                                    ...(user?.additional_vendors?.map(v => (v?.name)) || [])
                                ]);
                                return
                            }
                            setSelectedVendors([ e ]);
                        }}
                        className='form-bar-select filter-container'
                        showSearch
                        optionFilterProp="children"
                        defaultValue={'all'}
                        filterOption={(input, option) =>
                            option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                    >
                        <Option value="all">{t(`home.All Vendors`)}</Option>
                        <Option value={vendor?.name}>{vendor?.name}</Option>
                        {user?.additional_vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                            <Option key={'vendor-select' + index} value={vendor.name}>
                                {vendor.name}
                            </Option>
                        ))}
                    </Select> : <div className='form-bar-select filter-container'></div>
                }

                <Select className='form-bar-select filter-container' placeholder='Sort' defaultValue={-1} onChange={setSort}>
                    <Option key='new-to-old' value={-1}>{t(`sort.New to Old`)}</Option>
                    <Option key='old-to-new' value={1}>{t(`sort.Old to New`)}</Option>
                </Select>
            </div>

            {orders?.map((order, index) => (
                <div className="coupon-container">
                    <div className="coupon-label-row">
                        <div className='form-bar-select filter-container'>
                            {t(`fields.coupon.Code`)}
                        </div>
                        <div className='form-bar-select filter-container'>
                            {t(`fields.order_products.Payment At`)}
                        </div>
                        <div className='form-bar-select filter-container'>
                            {t(`fields.coupon.Amount`)}
                        </div>
                        {order?.rate_usd_tr_selling ? <div className='form-bar-select filter-container'>
                            {t(`fields.coupon.Amount TL`)}
                        </div> : null}
                    </div>
                    <div className="coupon-info-row">
                        <div className='form-bar-select filter-container'>
                            {order?.coupon?.code}
                        </div>
                        <div className='form-bar-select filter-container'>
                            {loncaFormatDate(order?.payment_at)}
                        </div>
                        <div className='form-bar-select filter-container'>
                            ${order?.discount}
                        </div>
                        {order?.rate_usd_tr_selling ? <div className='form-bar-select filter-container'>
                            ₺{Math.round(order?.discount * order?.rate_usd_tr_selling * 10) / 10}
                        </div> : null}
                    </div>
                </div>
            ))}
        </div>
    )
};

export default UsedCoupons;