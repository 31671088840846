import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;


export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (query) => {
    const mode = 'query_list'
    const { data } = await axios.post(`${url}/product_parent`, { query, mode });

    return data;
  },
);

export const fetchAllProductsHandle = createAsyncThunk(
  'products/fetchAllProducts',
  async (vendorId) => {
    const mode = 'all_list'
    const { data } = await axios.post(`${url}/product_parent`, { query: { filter: { vendor: vendorId } }, mode });

    return data;
  },
);

export const fetchProduct = (id) => axios.get(`${url}/product_parent/${id}`);

export const fetchProductByHandle = ({handle, region, mode}) =>{
  return axios.post(`${url}/product_parent/handle_admin`, {region, mode, handle});
}

export const fetchUpdateProduct = (id, changedFields, imageLinks, mainImage, chartImage, videoLinks) => axios.patch(`${process.env.REACT_APP_BASE_ENDPOINT}/product_parent/one/${id}`, {
  changedFields,
  imageLinks,
  main_image: mainImage,
  chartImage,
  videoLinks
});

export const fetchCreateProduct = (fields, uploadedLinks, mainImage, chartImage, vendorLastProductCount) => axios.post(
  `${process.env.REACT_APP_BASE_ENDPOINT}/product_parent/create`,
  {
    fields,
    uploadedLinks,
    main_image: mainImage,
    chartImage,
    vendorLastProductCount
  },
);

export const fetchUpdateStatus = (id, status) => axios.patch(
  `${process.env.REACT_APP_BASE_ENDPOINT}/product_parent/status/${id}`,
  {
    status,
  },
);

export const fetchDelete = (id) => axios.delete(
  `${process.env.REACT_APP_BASE_ENDPOINT}/product_parent/one/${id}`,
);

export const fetchUploadUrl = (key, type) => axios.post(`${url}/product_parent/s3-url-admin`, { key, type });

export const fetchNewSkuCode = () => axios.get(`${url}/product_parent/sku/`);

export const fetchStockCodes = (cartItems) => axios.post(`${url}/product_parent/stock_code/`, { cartItems });

export const exportProducts = (query) => {
  return axios.post(`${url}/product_parent/product-export`, { query });
};

export const importProductsTemplate = (params) => {
  return axios.get(`${url}/product_parent/product-import-template`, { params });
};

export const importProducts = ({ data, isEntry }) => {
  return axios.post(
    `${url}/product_parent/product-import`, 
    { data, isEntry }
  );
};

const productSlice = createSlice({
  name: 'product',
  initialState: {
    filter: {
      page: 1,
      limit: 100,
    },
    products: [],
    allProductsHandles: [],
    status: {
      fetchProducts: 'idle',
      fetchAllProductsHandle: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchProducts.pending]: (state) => {
      state.status.fetchProducts = 'pending';
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.status.fetchProducts = 'succeeded';
      state.products = action.payload.products;
      state.count = action.payload.count;
    },
    [fetchProducts.rejected]: (state, action) => {
      state.status.fetchProducts = 'rejected';
      state.error = action.payload;
    },

    [fetchAllProductsHandle.pending]: (state) => {
      state.status.fetchAllProductsHandle = 'pending';
    },
    [fetchAllProductsHandle.fulfilled]: (state, action) => {
      state.status.fetchAllProductsHandle = 'succeeded';
      if(action.payload?.length > 0) {
        state.allProductsHandles = action.payload;
      }
    },
    [fetchAllProductsHandle.rejected]: (state, action) => {
      state.status.fetchAllProductsHandle = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = productSlice.actions;

export default productSlice.reducer;
