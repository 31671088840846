import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select, DatePicker, notification, InputNumber, Checkbox } from 'antd';
import "./EditCoupon.css"
import { fetchUpdate, fetchCreate, fetchCoupon } from "../../redux/couponSlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import { loncaRound } from "../../helpers";

import "dayjs/locale/tr";
import LocaleProvider from 'antd/lib/locale-provider';
import tr_locale from "antd/es/date-picker/locale/tr_TR"; 
import 'moment/locale/tr';

const EditCoupon = () => {
    const navigate = useNavigate();
	const { t } = useTranslation('translation');
    const { id } = useParams();
	const { Option } = Select;

    const { vendor } = useSelector((state) => state.vendor);	
	const { languagePath } = useSelector((state) => state.user);

	const { RangePicker } = DatePicker;
	const [form] = Form.useForm();
	const [currencies, setCurrencies] = useState([]);
	const [currency, setCurrency] = useState();
    const [coupon, setCoupon] = useState();
	const [showDate, setShowDate] = useState(coupon?.start_date && coupon?.end_date);

    const addNew = id === "new";

    useEffect(() => {
		if(!addNew) {
            fetchCoupon({ _id: id })
                .then(res => {
                    setCoupon({...res[0], discount_amount: res[0]?.discount_type === "Absolute" ? res[0]?.discount_amount : res[0]?.discount_amount * 100});
                    form.setFieldsValue({
                        status: res[0]?.status,
                        discount_type: res[0]?.discount_type,
                        code: res[0]?.code,
                        description: res[0]?.description,
                        amount: res[0]?.discount_type === "Absolute" ? res[0]?.discount_amount : res[0]?.discount_amount * 100,
                        date: [moment(res[0]?.start_date || new Date()), moment(res[0]?.end_date || new Date())],
                        currency: res[0]?.currency || "USD",
                        show_date: res[0]?.start_date && res[0]?.end_date
                    })
					setShowDate(!!(res[0]?.start_date && res[0]?.end_date))
                })
                .catch(err => console.log(err))
        } else {
            form.setFieldsValue({
                status: "Active",
                discount_type: "Percentage",
                code: "",
                description: "",
                amount: 0,
                currency: "USD",
                show_date: false
            });
            setCoupon(coupon => ({...coupon, discount_type: "Percentage", code: "", description: "", currency: "USD", discount_amount: 0, status: "Active"}));
        }
	}, [form, id, addNew])

	useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			setCurrencies(res?.data);
			setCurrency(res?.data?.find(c => (c?.abbreviation === coupon?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD")))
		  })
	}, [coupon?.currency])

	const handleSubmit = () => {
		let updatedCoupon = { 
			...coupon, 
			discount_amount: coupon?.discount_type === "Absolute" ? coupon?.discount_amount : loncaRound(coupon?.discount_amount / 100),
            vendor: vendor?._id
		}

		if(!showDate) {
			updatedCoupon.start_date = null;
			updatedCoupon.end_date = null;
		}

		if(!addNew) fetchUpdate(coupon?._id, updatedCoupon)
			.then((res) => {
				notification['success']({
					message: t(`errors.Coupon saved successfully`)
				});
			})
			.catch((err) => {
				console.log(err)
				notification['error']({
					message: t(`errors.Coupon couldn't saved successfully`)
				});
			});
			
		if(addNew) {
			fetchCreate(updatedCoupon)
				.then((res) => {
					notification['success']({
						message: t(`errors.Coupon created successfully`)
					});
                    navigate(`${languagePath}/coupons`);
				})
				.catch((err) => {
					console.log(err)
					notification['error']({
						message: t(`errors.Coupon couldn't created successfully`)
					});
				});
		}
	}

	return(
		<div className="container">
			<div className="coupon-details">
				<Form
					form={form}
					className="coupon-form"
					onFinish={handleSubmit}
					layout="vertical"
				>
					<div className="header-save">
						<h2 className="coupon-header">{coupon?.code ? coupon?.code : t(`fields.coupon.Code`)}</h2>
						<div>
							<Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`buttons.Save`)}
							</Button>
							<Button type="secondary" onClick={() => {
								navigate(`${languagePath}/coupons`);
							}}>
								{t(`buttons.Cancel`)}
							</Button>
						</div>
					</div>
					<div className="form-top">
						<div className="form-left">
							<Form.Item label={t(`fields.coupon.Code`)} name="code">
								<Input 
									value={coupon?.code} 
									onChange={(e) => {
										setCoupon({...coupon, code: e.target.value.trim().replaceAll(" ", "").toUpperCase()})
									}}
								/>
							</Form.Item>
							<Form.Item label={t(`fields.coupon.Description`)} name="description">
								<Input 
									value={coupon?.description} 
									onChange={(e) => {
										setCoupon({...coupon, description: e.target.value})
									}}
								/>
							</Form.Item>
							<Form.Item label={t(`fields.coupon.Amount`)} name="amount">
								<InputNumber
									prefix={coupon?.discount_type === "Absolute" ? currency?.symbol : "%" }
									value={coupon?.discount_amount === 0 ? "" : coupon?.discount_amount} 
									onChange={(e) => {
										setCoupon({
											...coupon, 
											discount_amount: loncaRound(e)
										})
									}}
									style={{ width: "100%" }}
								/>
							</Form.Item>
							<Form.Item label={t(`fields.new_products.Vendor`)}>
                                <Input 
									value={vendor?.name} 
									disabled
								/>
							</Form.Item>

							<p className="date-checkbox">
								<Checkbox checked={showDate} onChange={(e) => setShowDate(e.target.checked)}>
									{t(`placeholders.I want to specify date interval`)}
								</Checkbox>
							</p>

							{ showDate ? 
							<LocaleProvider >
								<Form.Item label={`${t(`fields.coupon.Start Date`)} - ${t(`fields.coupon.End Date`)}`} name="date">
									<RangePicker
										locale={languagePath === "" ? tr_locale : null}	
										showTime
										value={[coupon?.start_date || new Date(), coupon?.end_date || new Date()]}
										onChange={([start, end]) => {
											setCoupon({...coupon, start_date: new Date(start._d), end_date: new Date(end._d)})
										}}
										style={{ width: "100%" }}
									/>
								</Form.Item> 
							</LocaleProvider>
							: null}
						</div>
						<div className="form-right">
							<Form.Item name="status" label={t(`fields.product.Status`)}>
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											status: e
										})
									}}
								>
									<Option value="Active">{t(`status.Active`)}</Option>
									<Option value="Passive">{t(`status.Passive`)}</Option>
									<Option value="Archived">{t(`status.Archived`)}</Option>
								</Select>
							</Form.Item>
							<Form.Item name="discount_type" label={t(`fields.coupon.Type`)}>
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											discount_type: e
										})
									}}
								>
									<Option value="Percentage">{t(`fields.coupon.Percentage`)}</Option>
									<Option value="Absolute">{t(`fields.coupon.Absolute`)}</Option>
								</Select>
							</Form.Item>

							{ coupon?.discount_type === "Absolute" ? 
							<Form.Item name="currency" label={t(`fields.coupon.Currency`)}>
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											currency: e
										})
										setCurrency(currencies?.find(c => c?.abbreviation === e))
									}}
								>
									{currencies?.map(c => c?.abbreviation)?.map(currency => (
										<Option value={currency}>{currency}</Option>
									))}
								</Select>
							</Form.Item> : null
							}
						</div>
					</div>
				</Form>
			</div>
		</div>
	)
};

export default EditCoupon;