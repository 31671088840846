import { Button } from 'antd';
import { Invoice, Pagination } from '../../components';
import { useTranslation } from "react-i18next";
import './Invoices.css';
import { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { fetchSupplierInvoices } from '../../redux/supplierInvoiceSlice';
import { Form, Select } from 'antd';


function Invoices() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [filter,setFilter] = useState({ limit: 100, page: 1, sort: { "createdAt" : -1 } })


  const { vendor } = useSelector((state) => state.vendor);
  const { languagePath } = useSelector((state) => state.user);
  const [invoices, setInvoices]  = useState([])
  
  useEffect(() => {
    setFilter({...filter, filter :{vendor  : vendor?._id}})
  },[vendor])

  useEffect(() => {
    if(!vendor?._id || !filter?.filter?.vendor) return;
    fetchSupplierInvoices(filter).then(
      (res) => {
        setInvoices(res.data)
      }
    ).catch((err) => {
      if (err?.response?.status === 404) {
        setInvoices([])
      }
      console.log(err)
    })
   
  }, [vendor,filter])


  return (
    <div className="list-invoices-container">
      <div className="list-invoices-nav">
          <h2>{t('home.Invoices')}</h2>
      <div/>

        <div>
          <Button onClick={() => navigate(`${languagePath}/invoice/new`)}>{t(`buttons.Add New Invoice`)}</Button>
        </div>
      </div>
      <div className="list-invoices-filter" >
                <Form
                    form={form}
                    layout="inline"
                    initialValues={{
                        date: '{"createdAt": -1}',
                    }}
                  
                >   
                    <Form.Item name="date" style={{ width: '200px' }}>
                        <Select
                            onChange={(value) => {
                              const newValue = JSON.parse(value);
                              setFilter({...filter , sort : newValue})}}
                        >
                            <Option value='{}'>{t(`sort.Sort`)}</Option>
                            <Option value='{"createdAt": -1}'>{t(`sort.Date_New to Old`)}</Option>
                            <Option value='{"createdAt": 1}'>{t(`sort.Date_Old to New`)}</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
      <div className="list-invoice-table-head">
        <div className="list-invoice-table-check">*</div>
        <div className="list-invoice-table-code">{t(`fields.invoice.Code`)}</div>
        <div className="list-invoice-table-vendor">{t(`home.vendor`)}</div>
        <div className="list-invoice-table-created-at">{t(`fields.invoice.Created At`)}</div>
      </div>
      {invoices?.map((item) => (
        <Invoice key={item._id} item={item} />
      ))}
        <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );
}

export default Invoices;
