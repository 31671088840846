import React, { useEffect, useState } from "react";
import { Card, Descriptions, Row, Col, Typography } from "antd";
import "../Returns.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { t } from "i18next";

const { Title } = Typography;

const Return = ({ singleReturn }) => {
    const { selectedLanguage, languagePath } = useSelector((s) => s.user);
    const { product, order, refund_reason, customer } = singleReturn;
    const { main_image, names } = product;
    const { unique_order_id, delivery_date } = order;
    const { shipment_country } = customer;
    const [ namePrefix, setNamePrefix ] = useState("tr");
    const navigate = useNavigate();

    const handleProductNavigation = (handle) => {
        navigate(`../${languagePath}/product/${handle}`);
    }

    useEffect(() => {
        const prefix = selectedLanguage?.code === "gb" ? "en" : "tr";
        setNamePrefix(prefix)
    }, [selectedLanguage])
        
    return (
        <Card
            hoverable
            className="single-return-card"
            onClick={()=> handleProductNavigation(singleReturn?.product?.handle)}
        >
            <Row align="middle" gutter={32} >
                <Col span={5}>
                    <img
                        src={main_image}
                        alt={`${names}.${namePrefix}`}
                        className="return-image"
                    />
                </Col>
                <Col span={19}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label={t('returns.Product Name')}>{names?.[namePrefix]}</Descriptions.Item>
                        <Descriptions.Item label={t('returns.Unique Order ID')}>{unique_order_id}</Descriptions.Item>
                        <Descriptions.Item label={t('returns.Return Reason')}>{t(`returns.${refund_reason}`)}</Descriptions.Item>
                        <Descriptions.Item label={t('returns.Shipment Country')}>{shipment_country?.names?.[namePrefix]}</Descriptions.Item>
                        <Descriptions.Item label={t('returns.Delivery Date')}>
                            {new Date(delivery_date).toLocaleString()}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Card>
    );
};

export default Return;
