import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async ({
    vendorId, page, limit, sort, countryNames, selectedStar, selectedCountries
}) => {
    const result = await axios.post(`${url}/reviews/supplier-list`, {
        vendorId,
        page,
        limit,
        sort,
        countryNames,
        selectedStar,
        selectedCountries
    });

    return result?.data;
});

export const fetchReviewCounts = createAsyncThunk('reviews/fetchReviewCounts', async ({
    vendorId,
    countryNames,
    selectedCountries
}) => {
    const result = await axios.post(`${url}/reviews/supplier-list-count`, {
        vendorId,
        countryNames,
        selectedCountries
    });
    return result?.data;
});

export const fetchTopVendors = createAsyncThunk('reviews/fetchTopVendors', async ({
    selectedCountries,
    countryNames
}) => {
    const result = await axios.post(`${url}/reviews/top-vendors`, {
        selectedCountries,
        countryNames 
    });
    return result?.data;
});

const reviewSlice = createSlice({
    name: 'review',
    initialState: {
        filter: {
            page: 1,
            limit: 10,
            sort: { createdAt: -1 }
        },
        reviews: [],
        reviewCounts: [],
        topVendors: [],
        status: {
            fetchReviews: 'idle',
            fetchReviewCounts: 'idle',
            fetchTopVendors: 'idle',
        },
        error: null,
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        }
    },
    extraReducers: {
        [fetchReviews.pending]: (state) => {
            state.status.fetchReviews = 'pending';
        },
        [fetchReviews.fulfilled]: (state, action) => {
            state.status.fetchReviews = 'fulfilled';
            state.reviews = action.payload;
        },
        [fetchReviews.rejected]: (state, action) => {
            state.status.fetchReviews = 'rejected';
            state.error = action.error.message;
        },

        [fetchReviewCounts.pending]: (state) => {
            state.status.fetchReviewCounts = 'pending';
        },
        [fetchReviewCounts.fulfilled]: (state, action) => {
            state.status.fetchReviewCounts = 'fulfilled';
            state.reviewCounts = action.payload;
        },
        [fetchReviewCounts.rejected]: (state, action) => {
            state.status.fetchReviewCounts = 'rejected';
            state.error = action.error.message;
        },

        [fetchTopVendors.pending]: (state) => {
            state.status.fetchTopVendors = 'pending';
        },
        [fetchTopVendors.fulfilled]: (state, action) => {
            state.status.fetchTopVendors = 'fulfilled';
            state.topVendors = action.payload;
        },
        [fetchTopVendors.rejected]: (state, action) => {
            state.status.fetchTopVendors = 'rejected';
            state.error = action.error.message;
        }
    },
});

export const { setFilter } = reviewSlice.actions;

export default reviewSlice.reducer;