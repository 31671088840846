import { createSlice } from '@reduxjs/toolkit';

const fiterSlice = createSlice({
  name: 'filter',
  initialState: {
    cartFilter: {
      page: 1,
      limit: 100,
      sort: -1,
    },
    customerFilter: {
      page: 1,
      limit: 100,
      sort: -1,
    },
    additionalServiceFilter: {
      sort: {},
      filter: {},
    },
    orderFilter: {
      page: 1,
      limit: 100,
      sort: { checkout_date: -1 },
      filter: {},
    },
    invoiceFilter: {
      sort: {},
      filter: {},
    },
    error: null,
  },
  reducers: {
    setCartFilter: (state, action) => {
      state.cartFilter = action.payload;
    },
    setCustomerFilter: (state, action) => {
      state.customerFilter = action.payload;
    },
    setAddtionalServiceFilter: (state, action) => {
      state.additionalServiceFilter = action.payload;
    },
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    },
    setInvoiceFilter: (state, action) => {
      state.invoiceFilter = action.payload;
    },
  },
});

export const {
  setCartFilter,
  setCustomerFilter,
  setAddtionalServiceFilter,
  setOrderFilter,
  setInvoiceFilter,
} = fiterSlice.actions;

export default fiterSlice.reducer;
